import React from 'react';
import {Box, Card} from '@mui/material';
import {calculateMargin} from "./functions/calculateMargin";
import {getCursor} from "./functions/getCursor";
import {getHover} from "./functions/getHover";
import {getShadowStyles} from "./functions/getShadowStyles";
import LogoAndName from "../../components/LogoAndName";
import IconButton from "@mui/material/IconButton";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
const TeamCard = ({team, round, isLastRound, handleCardClick, backgroundColor, tournamentStarted, totalTeams, collegeLogos = [], handlePrediction, showPrediction= false  }) => {
    const height = isLastRound ? 140 : 70;
    const margin = calculateMargin(round, team, height, totalTeams)
    const img = collegeLogos.find((item) =>
        item.school.toLowerCase() === team.name.toLowerCase()
    );
    return (
        <Box sx={{ position: 'relative' }}>
            {showPrediction && (
                <IconButton
                    onClick={handlePrediction}
                    sx={{
                        position: 'absolute',
                        top: -20,
                        left: -20,
                        backgroundColor: 'light',
                        '&:hover': {
                            backgroundColor: 'grey',
                        },
                    }}
                >
                    <AutoFixHighIcon />
                </IconButton>
            )}
            <Card
                key={round}
                onClick={handleCardClick}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    height: height,
                    width: '145px',
                    marginTop: `${margin}px`,
                    maxWidth: '135px',
                    backgroundColor: backgroundColor,
                    cursor: getCursor(isLastRound),
                    '&:hover': getHover(isLastRound, tournamentStarted),
                    ...getShadowStyles(team, round, totalTeams),
                }}
            >
                <LogoAndName img={img} team={team} />
            </Card>
        </Box>
    )
};

export default TeamCard;
