export const getShadowStyles = (team, round, totalTeams) => {
    if (totalTeams === 16 && round === "5") {
        return {}
    }
    if ((totalTeams === 12 || totalTeams === 14)  && round === "5") {
        return {}
    }
    if (round !== "1" && round !== "4" && round !== "5" && round !== "2" && round !== "3") {
        return {};
    }
    if (!team.order && team.order !== 0) return {}; // Handle cases where order isn't defined

    if (team.order % 2 === 0) {
        // Even order: no bottom shadow
        return {
            boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
            borderTopLeftRadius: team.order % 2 === 1 ? 0 : undefined,
            borderTopRightRadius: team.order % 2 === 1 ? 0 : undefined,
            borderBottomLeftRadius: team.order % 2 === 0 ? 0 : undefined,
            borderBottomRightRadius: team.order % 2 === 0 ? 0 : undefined
        };
    } else {
        // Odd order: no top shadow
        return {
            borderTopLeftRadius: team.order % 2 === 1 ? 0 : undefined,
            borderTopRightRadius: team.order % 2 === 1 ? 0 : undefined,
            borderBottomLeftRadius: team.order % 2 === 0 ? 0 : undefined,
            borderBottomRightRadius: team.order % 2 === 0 ? 0 : undefined,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        };
    }
};
