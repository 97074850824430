import {Button, Container} from "@mui/material";
import {Link, useLoaderData} from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box
} from '@mui/material';
import hdate from "human-date";
import {Add} from "@mui/icons-material";
import React from "react";

export const SuperAdminIndex = () => {

    const data = useLoaderData();
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    return <Container>
        <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'},
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1200,
            mx: 'auto',
            mt: 2
        }}>
            <Typography variant="h6">Tournaments</Typography>
            <Button
                component={Link}
                to="/superAdmin/tournaments/create"
                variant="contained"
                color="primary"
                startIcon={<Add/>}
                sx={{mb: 2}}
            >
                New Tournament
            </Button>
        </Box>
        <TableContainer component={Paper} sx={{maxWidth: 1200, mx: 'auto', mt: 2}}>
            <Table sx={{minWidth: 650}} aria-label="tournament table">
                <TableHead>
                    <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                        <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">Type</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">Created Date</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">Tie Breaker</Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="subtitle1" fontWeight="bold">Actions</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.tournaments.map((tournament) => (
                        <TableRow
                            key={tournament.id}
                            sx={{'&:hover': {backgroundColor: '#fafafa'}}}
                        >
                            <TableCell>
                                <Typography variant="body1">{tournament.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        px: 1.5,
                                        py: 0.5,
                                        borderRadius: 1,
                                        backgroundColor: tournament.type === 'soccer' ? '#e3f2fd' :
                                            tournament.type === 'basketball' ? '#f3e5f5' :
                                                '#e8f5e9',
                                    }}
                                >
                                    {capitalizeFirstLetter(tournament.type)}
                                </Box>
                            </TableCell>
                            <TableCell>{hdate.relativeTime(tournament.created_at)}</TableCell>
                            <TableCell>{tournament.tie_breaker}</TableCell>
                            <TableCell align="center">
                                <Button
                                    component={Link}
                                    to={`/superAdmin/tournaments/${tournament.id}`}
                                >
                                    View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
}
