// third-party

// assets
import { Story, Fatrows, PresentionChart } from 'iconsax-react';

// type

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'group-widget',
  title: 'Dashboard',
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      breadcrumbs: false,
      url: '/dashboard',
      icon: icons.statistics
    },
    {
      id: 'create-pool',
      title: 'Create Pool',
      type: 'item',
      url: '/pools/create',
      icon: icons.data,
    }
  ]
};

export default widget;
