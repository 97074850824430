import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import supabase from "../services/useSupabase";
import {useLoaderData, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import hdate from "human-date";

export const Login = () => {
    const navigate = useNavigate();
    const data = useLoaderData()
    useEffect(() => {
        const {data: authListener} = supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                navigate('/dashboard');
            }
        });
        return () => {
            authListener.subscription.unsubscribe()
        };
    }, [navigate])

    return (<Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
            <Card variant="outlined">
                <CardContent>
                    <Auth
                        supabaseClient={supabase}
                        appearance={{theme: ThemeSupa}}
                        providers={['google']}
                    />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
            {data.invitedPools.map((pool, index) => (
                <Card
                    key={index}
                    sx={{
                        maxWidth: 400,
                        margin: "0 auto",
                        borderRadius: 3,
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                        padding: 2,
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            textAlign="center"
                            sx={{color: "#0072E5", marginBottom: 2}}
                        >
                            Invited Pools
                        </Typography>
                        <Divider sx={{marginBottom: 2}}/>

                        <Box sx={{marginBottom: 2}}>
                            <Typography variant="h5" fontWeight="bold">
                                {pool.pool}
                            </Typography>
                            <Typography variant="body2" >
                                {pool.tournament}
                            </Typography>
                        </Box>

                        <Box sx={{marginBottom: 2}}>
                            <Typography variant="body1">
                                {hdate.prettyPrint(pool.start)} to {hdate.prettyPrint(pool.end)}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Grid>
    </Grid>
);
};
