import isSuperAdmin from "../functions/isSuperAdmin";
import supabase from "../../services/useSupabase";
import {redirect} from "react-router-dom";

// Helper function to delete previous winners.
const deletePreviousWinners = async (id) => {
    const {error} = await supabase
        .from('tournament_winners')
        .delete()
        .eq('tournament_id', id);

    if (error) {
        throw new Response('Failed to delete previous tournament winners', {status: 500});
    }
};

// Helper function to insert new winners.
const insertNewWinners = async (winners) => {
    const {error} = await supabase.from('tournament_winners').insert(winners);
    if (error) {
        throw new Response(error.message, {status: 400});
    }
};

const updateWinners = async (tournamentID, tournamentWinners) => {
    await deletePreviousWinners(tournamentID);
    const winnersData = tournamentWinners.map((winner) => ({
        order: winner.order,
        round: winner.round,
        team_id: winner.team_id,
        tournament_id: tournamentID,
    }));
    await insertNewWinners(winnersData);
}

const updateMainInfo = async (tournamentID, info) => {
    let query = supabase.from('tournaments');
    query = query.upsert({
        id: tournamentID,
        name: info.name,
        description: info.description,
        tie_breaker: info?.tie_breaker || 0,
        tie_breaker_description: info?.tie_breaker_description || '',
        type: info?.type || 'football'
    }).eq('id', tournamentID);
    const {data, error} = await query.select().limit(1).single();
    if (error) {
        throw new Response('Failed to update tournament', {status: 500});
    }
    return data.id;
};

const updateTeams = async (tournamentID, teams) => {
    const {
        data: currentTeams,
        error
    } = await supabase.from('tournament_teams').select('id').eq('tournament_id', tournamentID);
    if (error) {
        throw new Response('Failed to fetch current teams', {status: 500});
    }

    const currentTeamIds = currentTeams.map(team => team.id);
    const newTeamIds = teams.map(team => team.id);

    const teamsToAdd = teams.filter(team => !currentTeamIds.includes(team.id));
    const teamsToRemove = currentTeams.filter(team => !newTeamIds.includes(team.id));
    const teamsToUpdate = teams.filter(team => currentTeamIds.includes(team.id));

    if (teamsToRemove.length > 0) {
        await supabase.from('tournament_teams').delete().in('id', teamsToRemove.map(team => team.id));
    }
    if (teamsToAdd.length > 0) {
        await supabase.from('tournament_teams').insert(teamsToAdd.map(team => (
            {
                tournament_id: tournamentID,
                name: team.name,
                rank: team?.rank || 0,
                order: team?.order || 0,
                starting_round: team?.starting_round || 0,
            })
        ));
    }
    if (teamsToUpdate.length > 0) {
        await supabase.from('tournament_teams').upsert(teamsToUpdate.map(team => {
            return {
                id: team.id,
                tournament_id: tournamentID,
                name: team.name,
                rank: team?.rank || 0,
                order: team?.order || 0,
                starting_round: team?.starting_round || 0,
            }
        }));
    }
};

const updateDates = async (startDateObj, endDateObj, tournamentID) => {
    const startDate = startDateObj?.date;
    const endDate = endDateObj?.date;
    if (startDate) {
        await supabase.from('tournament_dates').upsert({
            id: startDateObj?.id,
            date: startDate,
            date_type: 'start',
            name: startDateObj?.name,
            tournament_id: tournamentID
        });
    }

    if (endDate) {
        await supabase.from('tournament_dates').upsert({
            id: endDateObj?.id,
            date: endDate,
            date_type: 'end',
            name: endDateObj?.name,
            tournament_id: tournamentID
        });
    }
};

const updateScoringSettings = async (tournamentID, scoringSettings) => {
    const {error} = await supabase
        .from('tournament_scoring_settings')
        .delete()
        .eq('tournament_id', tournamentID);

    if (!error) {
        await supabase.from('tournament_scoring_settings').insert(scoringSettings.map(setting => ({
            tournament_id: tournamentID,
            round: setting?.round || 0,
            score: setting?.score || 0,
        })));
    }
};

export const tournamentAction = async ({request, params}) => {
    // Ensure the user has super admin privileges.
    if (!isSuperAdmin()) {
        return redirect('/');
    }

    let {tournamentID} = params;
    if (tournamentID === 'undefined') {
        tournamentID = undefined
    }

    const formData = await request.formData();
    const payload = Object.fromEntries(formData.entries());
    const data = JSON.parse(payload.formData);

    const tournamentWinners = data.tournament_winners;
    const teams = data.tournament_teams;
    const dates = data.tournament_dates;
    const scoringSettings = data.tournament_scoring_settings;

    const startDateObj = dates.find(item => item.date_type === 'start');
    const endDateObj = dates.find(item => item.date_type === 'end');

    tournamentID = await updateMainInfo(tournamentID, data);

    const canEdit = startDateObj?.date && new Date(startDateObj.date) > new Date()
    if (canEdit) {
        await updateTeams(tournamentID, teams);
    }

    if (tournamentWinners.length !== 0) {
        await updateWinners(tournamentID, tournamentWinners);
    }

    if (startDateObj || endDateObj) {
        await updateDates(startDateObj, endDateObj, tournamentID);
    }

    if (scoringSettings.length > 0) {
        await updateScoringSettings(tournamentID, scoringSettings);
    }

    return {
        redirect: redirect(`/superAdmin/tournaments/${tournamentID}`),
        success: true
    };
};
