import supabase from "../../services/useSupabase";
import {jwtDecode} from "jwt-decode";

const SUPER_ADMIN_ROLE = 'super_admin';

export default async function isSuperAdmin() {
    const sessionResponse = await supabase.auth.getSession();
    const session = sessionResponse?.data?.session;

    if (!session) return false;

    const decodedToken = jwtDecode(session.access_token);

    return decodedToken?.user_role === SUPER_ADMIN_ROLE;
}
