import PropTypes from 'prop-types';

// material-ui

// project-imports
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import {useAuth} from "../../AuthProvider";

// ==============================|| MAIN LOGO ||============================== //

export default function LogoSection({ isIcon, sx, to }) {
  const { isLoggedIn } = useAuth();
  return (
      isIcon ? <LogoIcon  /> : <Logo />
  );
}

LogoSection.propTypes = { isIcon: PropTypes.bool, sx: PropTypes.any, to: PropTypes.any };
