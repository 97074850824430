import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// third-party

// project import
import MainCard from 'components/MainCard';


// assets
import {getWinnerAvatar} from "../standings";
import {MdOutlineSportsMartialArts} from "react-icons/md";
import {TbMathXDivideY} from "react-icons/tb";
import {HiTrophy} from "react-icons/hi2";
import {Box, Button} from "@mui/material";
import React, {useState} from "react";
import FullScreenLoader from "../../components/FullScreenLoader";
import {getFinalRound, getQuarterFinalsTeams, getSemifinalsTeams} from "../home";

// ==============================|| EXPANDING TABLE - USER DETAILS ||============================== //

export default function StandingsExpandingDetails({data, standingsData}) {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Grid container spacing={2.5} sx={{pl: {xs: 0, sm: 5, md: 6, lg: 10, xl: 12}}}>
            <FullScreenLoader isLoading={isLoading}/>
            <Grid item xs={12} sm={5} md={4} xl={3.5}>
                <MainCard>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={2.5} alignItems="center">
                                {renderTeamGridItem(data.winner, 0, standingsData.collegeLogos, standingsData.winners, 'winner', 12, standingsData.totalTeams, data.incorrectPicksTeams || [])}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-around" alignItems="center">
                                <Stack spacing={0.5} alignItems="center">
                                    <Typography variant="h5">Winner</Typography>
                                </Stack>
                                <Divider orientation="vertical" flexItem/>
                                <Stack spacing={0.5} alignItems="center">
                                    <Typography variant="h5">{data.winner}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <List component="nav" aria-label="main mailbox folders"
                                  sx={{py: 0, '& .MuiListItem-root': {p: 0}}}>
                                <ListItem>
                                    <ListItemIcon>
                                        <HiTrophy size="15"/>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography color="secondary">Points</Typography>}/>
                                    <ListItemSecondaryAction>
                                        <Typography align="right">{data.points}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <TbMathXDivideY size="15"/>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography color="secondary">Possible Points</Typography>}/>
                                    <ListItemSecondaryAction>
                                        <Typography align="right">
                                            {data.possiblePoints}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <MdOutlineSportsMartialArts size="15"/>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography color="secondary">Tie Breaker</Typography>}/>
                                    <ListItemSecondaryAction>
                                        <Typography align="right">{data.tieBreaker}</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem sx={{mt: 2}}>
                                    <Button
                                        onClick={() => setIsLoading(true)}
                                        variant="outlined"
                                        color="primary"
                                        component={Link}
                                        fullWidth
                                        to={`/pools/${data.poolID}/entry/${data.entryID}`}>
                                        View Entry
                                    </Button>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12} sm={7} md={8} xl={8.5}>
                <Stack spacing={2.5}>
                    <MainCard title="Semi Finals">
                        <Grid container spacing={3}>
                            {data.semiFinals.map((team, index) => (
                                renderTeamGridItem(team, index, standingsData.collegeLogos, standingsData.winners, 'semis', 6, standingsData.totalTeams, data.incorrectPicksTeams || [])
                            ))}
                        </Grid>
                    </MainCard>
                    <MainCard title="Quarter Finals">
                        <Grid container spacing={3}>
                            {data.quarterFinals.map((team, index) => (
                                <Grid item xs={3} md={3} key={index}>
                                    {renderTeamGridItem(team, index, standingsData.collegeLogos, standingsData.winners, 'quarter', 3, standingsData.totalTeams, data.incorrectPicksTeams || [])}
                                </Grid>
                            ))}
                        </Grid>
                    </MainCard>
                </Stack>
            </Grid>
        </Grid>
    );
}

export function renderTeamGridItem(team, index, collegeLogos, winners, round, size, totalTeams, incorrectPicksTeams) {
    // Helper to compute justified content
    const gridJustifyContent = index % 2 === 0 ? 'flex-end' : 'flex-start';

    // Helper for obtaining the round number
    const getRoundNumber = (round) => {
        switch (round) {
            case 'semis':
                return getSemifinalsTeams(totalTeams);
            case 'quarter':
                return getQuarterFinalsTeams(totalTeams);
            case 'winner':
                return getFinalRound(totalTeams);
            default:
                return 0;
        }
    };

    const resolvedRoundNumber = getRoundNumber(round);

    // Helper to render repetitive Grid structure
    const renderGridItem = (content) => (
        <Grid
            item
            xs={size}
            md={size}
            key={index}
            sx={{
                display: 'flex',
                justifyContent: gridJustifyContent
            }}
        >
            {content}
        </Grid>
    );

    // Early return if there are no winners or no matching winners for the round
    if (winners.length === 0 || !winners[resolvedRoundNumber] || !incorrectPicksTeams.includes(team)) {
        return renderGridItem(getWinnerAvatar(team, collegeLogos));
    }

    // Rendering content for matching winners
    return renderGridItem(
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
            {/* Line overlay */}
            <Box sx={{ position: 'absolute', width: '100%', borderTop: '2px solid', zIndex: 1 }} />
            {/* Logo */}
            <Typography
                sx={{
                    '& img': {
                        filter: 'grayscale(100%) contrast(100%)',
                        WebkitFilter: 'grayscale(100%) contrast(100%)',
                        opacity: 1
                    }
                }}
            >
                {getWinnerAvatar(team, collegeLogos)}
            </Typography>
        </Box>
    );
}

StandingsExpandingDetails.propTypes = {data: PropTypes.any};
