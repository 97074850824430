import React from 'react';
import {CardContent, Tooltip, Typography} from '@mui/material';
import countryList from "country-list";
import emojiFlags from "emoji-flags";
import {NFLLogos} from "./NFLLogos";

const LogoAndName = ({img, team}) => (
    <Tooltip title={team.name}>
        <CardContent
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px !important'
            }}>

            <Typography sx={{fontSize: '1.5rem'}}>
                {!img?.logo && <NFLLogos name={team.name} />}
                {img?.logo &&
                    <img alt="logo" src={img.logo} style={{width: '30px', height: '30px', marginRight: '4px'}}/>}
                {img === undefined && countryList.getCode(team.name) ? emojiFlags.countryCode(countryList.getCode(team.name))?.emoji : ''}
            </Typography>

            <Typography
                sx={{
                    fontSize: '.9rem',
                    overflow: 'hidden',
                    maxWidth: '70px', // Set a max width
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginLeft: '4px'
                }}
            >
                {team.name}
            </Typography>

            <Typography sx={{fontSize: '.7rem', color: 'gray'}}>
                {team?.rank ? ` (${team.rank})` : ''}
            </Typography>

        </CardContent>
    </Tooltip>
);

export default LogoAndName;
