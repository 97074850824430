import {Navigate, useLoaderData} from "react-router-dom";
import {useAuth} from "../AuthProvider";
import {Container, Grid, Typography} from "@mui/material";
import AvailableTournament from "./components/AvailableTournament";

const CreatePool = () => {
    const {user} = useAuth();
    const tournaments = useLoaderData();
    if (!user) {
        return <Navigate to='/login' replace/>;
    }
    return <div>
        <Container>
            <Grid container spacing={1}>
                {
                    tournaments.length === 0 &&
                    <Typography variant="h5" component="div">No tournaments available</Typography>
                }
                <Grid container spacing={3}>{tournaments.map((item, index) => (
                    <Grid item xs={12} lg={4} md={6}>
                        <AvailableTournament tournament={item} key={index} />
                    </Grid>
                ))}</Grid>
            </Grid>
        </Container>
    </div>;
};

export default CreatePool;
