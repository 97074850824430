import {lazy} from 'react';

// project-imports
import DashboardLayout from 'layout/Dashboard';
import Loadable from "../components/Loadable";
import {loginLoader} from "./loginLoader";
import {dashboardLoader} from "../dashboard/dashboardLoader";
import Dashboard from "../dashboard";
import PoolEntryIndex from "../pools/entry";
import {poolIdEntryIdAction} from "../pools/entry/poolIdAction";
import {poolIdEntryIdLoader} from "../pools/entry/poolIdLoader";
import {ErrorPage} from "../ErrorPage";
import PoolsIndex from "../pools/home";
import {action} from "../pools/home/action";
import {loader} from "../pools/home/loader";
import Standings from "../pools/standings";
import {standingsAction} from "../pools/standingsAction";
import {standingsLoader} from "../pools/standingsLoader";
import {poolCreateLoader} from "../pools/poolCreateLoader";
import CreatePool from "../pools/create";
import {poolStartTournamentAction} from "../pools/poolStartTournamentAction";
import StartPool from "../pools/start";
import {SuperAdminIndex} from "../superAdmin";
import {superAdminLoader} from "../superAdmin/loader";
import {Tournament} from "../superAdmin/tournaments";
import {tournamentLoader} from "../superAdmin/tournaments/loader";
import {tournamentAction} from "../superAdmin/tournaments/action";

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));


// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <DashboardLayout/>,
            children: [
                {
                    path: 'dashboard',
                    loader: dashboardLoader,
                    element: <Dashboard/>

                },
                {
                    element: <PoolEntryIndex/>,
                    path: "pools/:poolID/entry/:entryID",
                    action: poolIdEntryIdAction,
                    loader: poolIdEntryIdLoader,
                    errorElement: <ErrorPage/>,
                },
                {
                    element: <PoolsIndex/>,
                    path: "pools/:poolID",
                    action: action,
                    loader: loader,
                    errorElement: <ErrorPage/>,
                },
                {
                    element: <Standings/>,
                    path: "standings/:poolID",
                    action: standingsAction,
                    loader: standingsLoader,
                    errorElement: <ErrorPage/>,
                },
                {
                    loader: poolCreateLoader,
                    element: <CreatePool/>,
                    path: "pools/create",
                    errorElement: <ErrorPage/>,
                },
                {
                    action: poolStartTournamentAction,
                    element: <StartPool/>,
                    path: "pools/start/:tournamentID",
                    errorElement: <ErrorPage/>,
                },
                {
                    element: <SuperAdminIndex/>,
                    loader: superAdminLoader,
                    path: "superAdmin",
                    errorElement: <ErrorPage/>,
                },
                {
                    element: <Tournament/>,
                    loader: tournamentLoader,
                    action: tournamentAction,
                    path: "superAdmin/tournaments/:tournamentID",
                    errorElement: <ErrorPage/>,
                },
                {
                    element: <Tournament/>,
                    loader: tournamentLoader,
                    action: tournamentAction,
                    path: "superAdmin/tournaments/create",
                    errorElement: <ErrorPage/>,
                },
            ]
        },
        {
            path: '*',
            element: <MaintenanceError/>
        }
    ]
};

export default MainRoutes;
