import supabase from "../services/useSupabase";
import {hasTournamentStarted} from "../db/hasTournamentStarted";
import {redirect} from "react-router-dom";
import {parseInt} from "lodash";
import {fetchCollegeLogos} from "./entry/functions/fetchCollegeLogos";

async function getUserPicks(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_picks')
        .select("*,tournament_teams(*),tournament_pool_entries(*,tournament_pool_tie_breakers(*))")
        .eq('pool_id', poolID)
    if (error) {
        throw new Response(error.message, {status: 402});
    }
    return data;
}

async function getWinners(tournamentID) {
    const winners = await supabase.from('tournament_winners').select('*').eq('tournament_id', tournamentID)
    return winners.data
}

const calculatePoints = (picks, roundMultiplier) => {
    return picks.reduce((acc, pick) => acc + (roundMultiplier[pick.round] || 0), 0);
};

function calculatePossiblePointsFor14Teams(userPicks, scoringSettings, winners) {
    let possiblePoints = 0
    const incorrectPicks = []
    const incorrectPicksTeams = []
    console.log('winners', winners)
    userPicks.forEach(pick => {
        Object.entries(scoringSettings).forEach(([round, score]) => {
            if (parseInt(pick.round) === parseInt(round)) {
                let addToScore = true
                const hasWinners = winners.filter(winner => winner.round === pick.round);
                if (hasWinners.length !== 0) {
                    const matchingWinner = winners.find(winner => winner.team_id === pick.team_id && winner.round === pick.round);
                    if (!matchingWinner) {
                        if (parseInt(pick.tournament_teams.starting_round) === 1) {
                            incorrectPicks.push(pick)
                            incorrectPicksTeams.push(pick.tournament_teams.name)
                        }
                        addToScore = false
                    }
                }
                // check if the user picked the same team in a different round
                incorrectPicks.forEach(incorrectPick => {
                    if (incorrectPick.team_id === pick.team_id) {
                        addToScore = false
                    }
                })
                if (addToScore) {
                    possiblePoints += score
                }
            }
        })
    })
    return {incorrectPicksTeams: incorrectPicksTeams, possiblePoints: possiblePoints};
}

function calculatePossiblePoints(userPicks = [], scoringSettings = {}, winners = [], totalTeams = 0) {
    if (totalTeams === 14) {
        return calculatePossiblePointsFor14Teams(userPicks, scoringSettings, winners)
    }
    let possiblePoints = 0
    const incorrectPicks = []
    const incorrectPicksTeams = []
    userPicks.forEach(pick => {
        Object.entries(scoringSettings).forEach(([round, score]) => {
            if (parseInt(pick.round) === parseInt(round)) {
                let addToScore = true
                winners.forEach(winner => {
                    // check if this round has a winner
                    // if the winner is not the same as the winner picked, do not add it to the possible points
                    // add the team to the incorrect picks so we can check if the user picked the same team in a different round
                    if (parseInt(winner.round) === parseInt(pick.round) && winner.team_id !== pick.team_id && parseInt(winner.order) === parseInt(pick.order)) {
                        incorrectPicks.push(pick)
                        incorrectPicksTeams.push(pick.tournament_teams.name)
                        addToScore = false
                    }
                })
                // check if the user picked the same team in a different round
                incorrectPicks.forEach(incorrectPick => {
                    if (incorrectPick.team_id === pick.team_id) {
                        addToScore = false
                    }
                })
                if (addToScore) {
                    possiblePoints += score
                }
            }
        })
    })
    switch (totalTeams) {
        case 12:
            possiblePoints = possiblePoints - 4
            break
        default:
            break
    }
    return {possiblePoints, incorrectPicksTeams}
}

const getUserData = (picks, winners, scoringSettings, lastRound, totalTeams) => {
    return Object.keys(picks).reduce((standings, userId) => {
        const userPicks = picks[userId];
        const matchingPicks = userPicks.filter(pick =>
            winners.some(winner => winner.team_id === pick.team_id && winner.round === pick.round)
        );
        const {
            possiblePoints,
            incorrectPicksTeams
        } = calculatePossiblePoints(userPicks, scoringSettings, winners, totalTeams)
        standings[userId] = {
            points: calculatePoints(matchingPicks, scoringSettings),
            possiblePoints: possiblePoints,
            incorrectPicksTeams: incorrectPicksTeams,
            winner: userPicks.find(pick => pick.round === lastRound)?.tournament_teams?.name || 'No pick',
            semiFinals: userPicks.filter(pick => pick.round === lastRound - 1).map(pick => pick.tournament_teams?.name),
            quarterFinals: userPicks.filter(pick => pick.round === lastRound - 2).map(pick => pick.tournament_teams?.name),
            poolID: userPicks[0].pool_id,
            id: userPicks[0].entry_id,
            entryID: userPicks[0].entry_id,
            tieBreaker: userPicks[0].tournament_pool_entries.tournament_pool_tie_breakers[0].tie_breaker,
        };
        return standings;
    }, {});
};

const organizeDataByUser = data => {
    const groupedByUser = data.reduce((acc, item) => {
        if (!acc[item.tournament_pool_entries.name]) {
            acc[item.tournament_pool_entries.name] = [];
        }
        acc[item.tournament_pool_entries.name].push(item);
        return acc;
    }, {});

    return groupedByUser;
};

const getOrderedPicksArray = picks => Object.entries(picks)
    .sort((a, b) => b[1].points - a[1].points)
    .map(([name, data]) => ({
        name,
        bracketName: name,
        ...data
    }));

const calculateTiebreakerPoints = (entries, targetTiebreaker) => {
    if (!entries || !Array.isArray(entries) || entries.length === 0) {
        return [];
    }

    // First, find the highest points score
    const highestPoints = Math.max(...entries.map(entry => entry.points));

    // Filter entries with the highest points
    const highestScorers = entries.filter(entry => entry.points === highestPoints);

    // If there's only one highest scorer, return the original entries unchanged
    if (highestScorers.length < 2) {
        return entries;
    }

    // Find valid tiebreaker scores (not exceeding target)
    const validTiebreakers = highestScorers
        .filter(entry => entry.tieBreaker <= targetTiebreaker)
        .map(entry => entry.tieBreaker);


    // If no valid tiebreakers, return original entries
    if (validTiebreakers.length === 0) {
        return entries;
    }

    // Find the closest tiebreaker to target without going over
    const closestTiebreaker = Math.max(...validTiebreakers);

    // Create a new array with updated points
    return entries.map(entry => {
        // Only update entries that have both the highest points AND the closest tiebreaker
        if (entry.points === highestPoints && entry.tieBreaker === closestTiebreaker) {
            return {
                ...entry,
                points: entry.points + 1
            };
        }
        return entry;
    });
};


export const standingsLoader = async ({request, params}) => {
    const user = await supabase.auth.getUser()
    if (!user.data.user) {
        return redirect('/login');
    }
    const {poolID} = params;
    if (!await hasTournamentStarted(poolID)) {
        throw new Error('The tournament has not started yet');
    }

    const poolInfo = await supabase.from('tournament_pools').select('*,tournaments(*,tournament_scoring_settings(*), tournament_teams(*))').eq('id', poolID).limit(1).single();
    const scoringArrayData = poolInfo.data.tournaments.tournament_scoring_settings || []

    let lastRound
    const totalTeams = poolInfo?.data?.tournaments?.tournament_teams?.length || 0;
    switch (totalTeams) {
        case 64:
            lastRound = 7;
            break;
        case 32:
            lastRound = 6;
            break;
        case 16:
        case 14:
        case 12:
            lastRound = 5;
            break;
        default:
            lastRound = 0;
            break
    }

    const scoringSettings = scoringArrayData.reduce((acc, item) => {
        acc[item.round] = item.score;
        return acc;
    }, {});
    const userPicks = await getUserPicks(poolID);
    const winners = await getWinners(poolInfo.data.tournament_id);

    const organizedPicks = organizeDataByUser(userPicks);
    const standings = getUserData(organizedPicks, winners, scoringSettings, lastRound, totalTeams);

    return {
        standings: calculateTiebreakerPoints(getOrderedPicksArray(standings), poolInfo.data.tournaments.tie_breaker || 0),
        winners: winners,
        tournamentName: poolInfo.data.tournaments.name,
        poolID: poolID,
        totalTeams,
        collegeLogos: await fetchCollegeLogos(),
    };
};

