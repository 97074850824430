// material-ui
import {useTheme} from '@mui/material/styles';
import {Box} from "@mui/material";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

export default function LogoIcon() {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="icon logo" width="100" />
         *
         */
        <Box sx={{ padding: '5px' }}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 690" width="50">
                <defs>
                    <style>{`.cls-1{fill:#4171fa;}`}</style>
                </defs>
                <path className="cls-1" d="M521.93,166.73V276.32A76.29,76.29,0,0,1,496.5,333a76.33,76.33,0,0,1,25.43,56.67V499.22a7.15,7.15,0,0,0,7.15,7.15h33.21c34.42-51.74,54.11-113.22,54.11-177.14V159.59H529.08A7.15,7.15,0,0,0,521.93,166.73Z"/>
                <path className="cls-1" d="M129.54,499.22V389.65A76.3,76.3,0,0,1,155,333a76.26,76.26,0,0,1-25.43-56.66V166.73a7.15,7.15,0,0,0-7.15-7.14H35.07V329.23c0,63.93,19.65,125.41,54,177.14h33.32A7.15,7.15,0,0,0,129.54,499.22Z"/>
                <path className="cls-1" d="M582.94,71.77v-.19L515.59,49.92A621.78,621.78,0,0,0,134.7,50L97.41,62,75.2,68.47,35.07,80.91v38.47h87.32a47.41,47.41,0,0,1,47.35,47.35V276.32a36.6,36.6,0,0,0,36.56,36.56v40.21a36.59,36.59,0,0,0-36.56,36.56V499.22a47.41,47.41,0,0,1-47.35,47.36h-1.83a315.14,315.14,0,0,0,93.68,70.06l30.06,14.21,3.55,1.93,69,33.17,8,4,70.33-33.16v-.15l41.61-19.84a316.66,316.66,0,0,0,94-70.21h-1.67a47.41,47.41,0,0,1-47.35-47.36V389.65a36.6,36.6,0,0,0-36.57-36.56V312.88a36.6,36.6,0,0,0,36.57-36.56V166.73a47.41,47.41,0,0,1,47.35-47.35H616.4V82.13ZM405.09,327c-13.38,56.81-35.26,83.39-53.87,109.85l46.69,56H253.65l46.65-55.89c-18.62-26.46-40.53-53.06-53.92-109.92-9.63-41.12-11.85-91.92-11.85-118.78H416.94C416.94,235.1,414.72,285.9,405.09,327Z"/>
                <polygon className="cls-1" points="312.69 297.19 276.4 297.19 305.81 319.08 293.77 359.82 325.58 333.82 357.27 359.82 344.8 319.63 375.07 297.19 337.41 297.19 324.72 258.75 312.69 297.19"/>
            </svg>
        </Box>
    );
}
