import _, {parseInt} from "lodash";
import {getOpponent} from "./getOpponent";

const FIRST_HALF_LIMIT = 4;
const SECOND_HALF_START_INDEX = 4;

const SECOND_HALF_ORDER = 4;
const FIRST_HALF_ORDER = 0;

export function saveTeamsByRank(team, bracket, setBracket) {
    const isTeamInSecondHalf = team.order > 5; // Check if the team belongs to the second half; renamed for clarity
    const bracketCopy = _.cloneDeep(bracket); // Deep clone the bracket

    // Extract and sort the relevant teams
    const {firstHalf, secondHalf} = extractAndSortTeams(bracketCopy, isTeamInSecondHalf);

    // Determine the opponent for the team
    const opponent = getOpponent(team, bracket, 1);

    // Get the updated teams list after filtering and adding the new team
    const updatedTeams = getUpdatedTeamsList(team, opponent, firstHalf, isTeamInSecondHalf);

    // Combine teams together using a helper function
    const combinedTeams = combineTeams(updatedTeams, secondHalf, isTeamInSecondHalf);

    // Save and return the updated bracket
    const updatedBracket = {...bracket, 2: combinedTeams};
    setBracket(updatedBracket);
    return updatedBracket;
}

// Extracted helper function to combine teams
function combineTeams(updatedTeams, secondHalf, isTeamInSecondHalf) {
    return isTeamInSecondHalf
        ? [...secondHalf, ...updatedTeams]
        : [...updatedTeams, ...secondHalf];
}

// Extracted helper function for better readability
function getUpdatedTeamsList(team, opponent, currentTeams, isTeamInSecondHalf) {

    // Removes the winners of round 1 and 5, those 2 should not be modified
    // Removes the opponent of the team, since we will add the new team
    const filteredTeams = currentTeams.filter(
        t => t.id !== opponent.id && !(t.rank === 1 && (t.order === 1 || t.order === 5))
    );

    // This is where we add the team, if it is not in the team list. The order is specified by the booleans
    const newTeamList = !currentTeams.some(t => t.id === team.id)
        ? [...filteredTeams, {...team, order: isTeamInSecondHalf ? SECOND_HALF_ORDER : FIRST_HALF_ORDER}]
        : filteredTeams;

    // Sort and update team order based on section
    return updateAndSortTeams(
        newTeamList,
        currentTeams,
        isTeamInSecondHalf ? SECOND_HALF_START_INDEX : FIRST_HALF_ORDER
    );
}

function extractAndSortTeams(bracket, isSecondHalf) {
    const mainTeams = bracket[2]
    const firstHalf = isSecondHalf
        ? mainTeams.slice(SECOND_HALF_START_INDEX, SECOND_HALF_START_INDEX + FIRST_HALF_LIMIT)
        : mainTeams.slice(0, FIRST_HALF_LIMIT)
    const secondHalf = isSecondHalf
        ? mainTeams.slice(0, FIRST_HALF_LIMIT)
        : mainTeams.slice(SECOND_HALF_START_INDEX, SECOND_HALF_START_INDEX + FIRST_HALF_LIMIT)
    return {firstHalf, secondHalf};
}

function updateAndSortTeams(filteredTeams, currentTeams, baseOrder) {
    // find the winners of round 1 and 5
    const rankOneTeams = currentTeams.filter(t => t.rank === 1 && (t.order === 1 || t.order === 5));
    // Sort and assign with winners
    const sortedTeams = sortAndAssignOrder(filteredTeams, baseOrder).concat(rankOneTeams);
    const finalSortedTeams = _.sortBy(sortedTeams, 'order');
    if (finalSortedTeams.length > FIRST_HALF_LIMIT) {
        finalSortedTeams.pop();
    }
    return finalSortedTeams;
}

function sortAndAssignOrder(teamList, initialOrder) {
    const ADD_ONE_THRESHOLD = 1; // First 3 team indices add +1
    const ADD_TWO_THRESHOLD = 4; // Next 4 team indices add +2

    function calculateOrder(index) {
        if (index < ADD_TWO_THRESHOLD) {
            return index < ADD_ONE_THRESHOLD ? initialOrder : index + ADD_ONE_THRESHOLD + initialOrder;
        }
        return index + ADD_TWO_THRESHOLD + initialOrder;
    }

    return teamList
        // this sorts them by rank, with the highest rank first (highest number is the worst rank)
        .sort((a, b) => (b.rank || -1) - (a.rank || -1))
        .map((team, index) => {
            // Calculate the order based on the index
            team.order = calculateOrder(index);
            return team;
        });
}

/**
 * Adds a team to the next round and updates the round items.
 * @param {{id: string, name: string, rank: null, order: number, created_at: string, tournament_id: string, starting_round: number}} team - The team to be added to the next round.
 * @param {Array<{
 *   name: string,
 *   order: number,
 *   id: string
 * }>} nextRoundTeams - Structured as an array of objects representing teams in the next round that want to be updated.
 * @param {string} nextRound - The key for the next round in the bracket.
 * @param {Function} setBracket - Function to update the bracket state.
 * @param {Object<{
 *   [round: string]: Array<{
 *     name: string,
 *     order: number,
 *     id: string
 *   }>
 * }>} bracket - The current state of the bracket, with keys representing rounds and values being arrays of team objects.
 * @param {number} totalTeams - The total number of teams in the bracket.
 */
export function addToNextRound(team, nextRoundTeams, nextRound, setBracket, bracket, totalTeams) {

    const updateNextRound = (order) => {
        const index = nextRoundTeams.findIndex(item => item.order === order);
        nextRoundTeams[index] = {
            name: team.name,
            order: order,
            id: team.id,
            rank: team.rank
        };
        // We sort the round items by order so that the teams are in the correct order.
        const b = {...bracket, [parseInt(nextRound)]: _.sortBy(nextRoundTeams, 'order')}
        setBracket(b)
    }

    const isOdd = (num) => {
        return num % 2;
    }

    /**
     * If you had selected team A to win, but now you have selected team B. We need to remove team A from any of the future rounds.
     * This will update the next rounds to "TBA" because you replace team A with team B.
     * Therefor A changes to TBA in the next rounds
     */
    const updateConsequentMatches = () => {
        // Team is the team that we just moved to the next round.
        // Here, we find the opponent of the team that we just moved to the next round in the previous round.
        // For example in Round 1, A vs B, we move team A to next round. We need to remove team B from the next rounds.
        // We find the opponent of team A in the previous round, which is B.
        const opponent = bracket[nextRound - 1][isOdd(team.order) ? team.order - 1 : team.order + 1]
        // We loop through the bracket and find the opponent of the team that we just moved to the next round.
        const bb = []
        Object.entries(bracket).forEach((([bracketRoundKey]) => {
            // This is a check to see if the round is greater than the next round. This would be round 2, 3, 4, etc.
            if (parseInt(bracketRoundKey) >= nextRound) {
                bracket[bracketRoundKey].forEach((futureTeam, indexOfKey) => {
                    // Here we find team B in the next rounds and replace it with TBA.
                    if (futureTeam.name === opponent.name) {
                        bracket[bracketRoundKey][indexOfKey] = {
                            name: 'TBA',
                            order: futureTeam.order
                        }
                        const b = {...bracket}
                        bb.push(b)
                        setBracket(b)
                    }
                })
            }
        }))
        return bb
    }

    /**
     * We determine the order of where the team will go in the next round.
     * If in round 1 the order is 0, then in round 2 the order will be 0 [[0],[0]]
     * if in round 1 the order is 1, then in round 2 the order will be 0 [[1],[0]]
     * if in round 1 the order is 2, then in round 2 the order will be 1 [[2],[1]]
     * if in round 1 the order is 3, then in round 2 the order will be 1 [[3],[1]]
     * if in round 1 the order is 4, then in round 2 the order will be 2 [[4],[2]]
     * if in round 1 the order is 5, then in round 2 the order will be 2 [[5],[2]]
     * if in round 1 the order is 6, then in round 2 the order will be 3 [[6],[3]]
     * if in round 1 the order is 7, then in round 2 the order will be 3 [[7],[3]]
     * @type {number}
     */
    let nextRoundTeamOrder = Math.floor(team.order / 2);
    const currentRound = nextRound - 1
    /**
     * In a 64 team bracket, the team order for the last 8 is the following:
     */
    if (currentRound === 4 && totalTeams === 64) {
        /**
         *  0
         *  vs will go to the next round with order 0 ['this winner','','', '']
         *  1
         */
        if (team.order < 2) {
            nextRoundTeamOrder = 0;
        }
        /**
         *  2
         *  vs will go to the next round with order 2 ['','','this winner', '']
         *  3
         */
        else if (team.order < 4) {
            nextRoundTeamOrder = 2;
        }
        /**
         *  4
         *  vs will go to the next round with order 1 ['','this winner','', '']
         *  5
         */
        else if (team.order < 6) {
            nextRoundTeamOrder = 1;
        }
        /**
         *  6
         *  vs will go to the next round with order 3 ['','','', "this winner"]
         *  7
         */
        else if (team.order < 8) {
            nextRoundTeamOrder = 3;
        }
    }
    /**
     * In a 12 team bracket, we have teams already in the second round, so we need to double the nextRoundTeamOrder
     */
    if (totalTeams === 12 && currentRound === 1) {
        nextRoundTeamOrder = nextRoundTeamOrder + nextRoundTeamOrder
    }

    /**
     * In a 14 team bracket, It’s not a linear tournament.
     * The teams are re-seeded after the first round to guarantee the #1 seed plays the lowest seeded remaining team
     */
    if (totalTeams === 14 && currentRound === 1) {
        updateConsequentMatches();
        return saveTeamsByRank(team, bracket, setBracket)
    }
    // if (totalTeams === 14 && (team.order > 1 && team.order <= 7) && currentRound === 1) {
    //     nextRoundTeamOrder = nextRoundTeamOrder + 1
    // }
    // if (totalTeams === 14 && (team.order > 7) && currentRound === 1) {
    //     nextRoundTeamOrder = nextRoundTeamOrder + 2
    // }
    updateNextRound(nextRoundTeamOrder);
    return updateConsequentMatches();
}
