import {parseInt} from "lodash";

export function getBackgroundColor(winners, round, team, tournamentTeams = []) {
    if (!winners) {
        return ''
    }
    const startingTeam = tournamentTeams.find(tournamentTeam => tournamentTeam.id === team.id && parseInt(tournamentTeam.starting_round) === parseInt(round))
    if (startingTeam) {
        return ''
    }
    if (!winners.some(winner => parseInt(winner.order) === parseInt(team.order))) {
        return ''
    }
    return winners.some(winner => winner.id === team.id) ? '#DBF9E1' : '#FAE0DD'
}
