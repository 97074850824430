import React, {useState} from 'react';
import {Form, Link} from 'react-router-dom';
import {Box, Button, Paper, Stack, TextField, Typography} from '@mui/material';
import FullScreenLoader from "../../components/FullScreenLoader";
import Divider from "@mui/material/Divider";

const TieBreaker = ({
                        poolID,
                        entryID,
                        bracket,
                        inputValue,
                        handleInputChange,
                        tournamentStarted,
                        entryName,
                        tieBreakerDescription = ''
                    }) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Box
            component={Form}
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: 300,
                margin: 'auto', // Center horizontally
                padding: 1, // Add some padding
                zIndex: 1000 // Ensure it's above other content
            }}
            method="post"
            action={`/pools/${poolID}/entry/${entryID}`}
        >
            <FullScreenLoader isLoading={isLoading}/>
            {!tournamentStarted && <TextField
                name="tieBreaker"
                label={`Tie breaker`}
                variant="outlined"
                sx={{ backgroundColor: 'white', padding: 1, borderRadius: 2}}
                value={inputValue}
                onChange={handleInputChange}
                disabled={tournamentStarted}
                helperText={tieBreakerDescription}
            />}
            <input type="hidden" name="bracket" value={JSON.stringify(bracket)}/>
            {
                !tournamentStarted && <Button type="submit" variant="contained" color="primary">Save</Button>
            }
            {tournamentStarted && (
                <Paper
                    elevation={2}
                    sx={{
                        p: 3,
                        borderRadius: 2,
                        background: 'linear-gradient(to right, #ffffff, #f8f9fa)'
                    }}
                >
                    <Stack spacing={1}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <Typography variant="h6" color="primary.main" sx={{textAlign: 'center', width: '100%'}}>
                                {entryName}
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2}}>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary" sx={{mb: 0.5}}>
                                    Tie Breaker: {inputValue}
                                </Typography>
                            </Box>
                            <Box>
                                <Button onClick={() => setIsLoading(true)} variant="contained" component={Link}
                                        to={`/standings/${poolID}`}>
                                    Standings
                                </Button>
                            </Box>
                        </Box>
                    </Stack>
                </Paper>
            )}
        </Box>
    );
}

export default TieBreaker;
