// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

export default function LogoMain() {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
      <>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1930 470">
          <defs>
            <style>{`.cls-1{fill:#4171fa;}.cls-2{fill:#1e1e1e;}`}</style>
          </defs>
          <path className="cls-1"
                d="M1562.05,310a7.37,7.37,0,0,0-5.24,2,6.89,6.89,0,0,0-2.14,5.29,7.23,7.23,0,0,0,7.38,7.46,7,7,0,0,0,5.06-2.09,7.19,7.19,0,0,0,2.13-5.37,6.92,6.92,0,0,0-2.13-5.29A7.16,7.16,0,0,0,1562.05,310Z"/>
          <path className="cls-1"
                d="M1614.16,277.3a20.75,20.75,0,0,1,7-4.53,24.21,24.21,0,0,1,8.93-1.6,22.23,22.23,0,0,1,9,1.82,23.56,23.56,0,0,1,7.65,5.47l7.46-7a27.64,27.64,0,0,0-10.66-7.78,38.39,38.39,0,0,0-27.19-.26,31.55,31.55,0,0,0-10.57,6.71,30.5,30.5,0,0,0-7,10.12,34.12,34.12,0,0,0,0,25.59,31.13,31.13,0,0,0,17.5,16.84,36.16,36.16,0,0,0,13.24,2.36,35.71,35.71,0,0,0,14-2.67,27.94,27.94,0,0,0,10.66-7.82l-7.46-7a22.82,22.82,0,0,1-7.65,5.55,22.07,22.07,0,0,1-9,1.83,24.21,24.21,0,0,1-8.93-1.6,20.9,20.9,0,0,1-7-4.53,21.26,21.26,0,0,1-4.66-6.93,23.76,23.76,0,0,1,0-17.6A21.15,21.15,0,0,1,1614.16,277.3Z"/>
          <path className="cls-1"
                d="M1738.38,270.06a31.44,31.44,0,0,0-10.61-6.66,37,37,0,0,0-13.34-2.36,36.46,36.46,0,0,0-13.41,2.4,32.46,32.46,0,0,0-10.66,6.71,29.94,29.94,0,0,0-7,10.13,33.66,33.66,0,0,0,0,25.41,30.39,30.39,0,0,0,7,10.17,31.81,31.81,0,0,0,10.7,6.76,38.66,38.66,0,0,0,26.71,0,30.91,30.91,0,0,0,17.59-16.88,32.07,32.07,0,0,0,2.48-12.75,32.42,32.42,0,0,0-2.48-12.8A30.41,30.41,0,0,0,1738.38,270.06Zm-3.82,31.77a21.18,21.18,0,0,1-4.57,6.93,20.75,20.75,0,0,1-6.89,4.53,23.06,23.06,0,0,1-8.67,1.6,23.75,23.75,0,0,1-8.75-1.6,20,20,0,0,1-7-4.58,21.8,21.8,0,0,1-4.62-7,22.37,22.37,0,0,1,4.62-24.38,20,20,0,0,1,7-4.58,23.75,23.75,0,0,1,8.75-1.6,23.06,23.06,0,0,1,8.67,1.6,21,21,0,0,1,6.93,4.53,20,20,0,0,1,4.57,6.93,23.44,23.44,0,0,1,1.6,8.8A22.87,22.87,0,0,1,1734.56,301.83Z"/>
          <polygon className="cls-1"
                   points="1849.58 324.13 1849.4 261.93 1839.9 261.93 1815.62 303.15 1790.94 261.93 1781.43 261.93 1781.43 324.13 1792.45 324.13 1792.45 283.46 1812.88 317.11 1818.13 317.11 1838.47 282.83 1838.47 324.13 1849.58 324.13"/>
          <path className="cls-1"
                d="M368.17,116.9v72.6A50.55,50.55,0,0,1,351.32,227a50.55,50.55,0,0,1,16.85,37.54v72.59a4.74,4.74,0,0,0,4.73,4.74h22a212.25,212.25,0,0,0,35.84-117.36V112.17H372.9A4.73,4.73,0,0,0,368.17,116.9Z"/>
          <path className="cls-1"
                d="M108.21,337.17V264.58A50.55,50.55,0,0,1,125.06,227a50.55,50.55,0,0,1-16.85-37.54V116.9a4.73,4.73,0,0,0-4.73-4.73H45.63V224.55A212.56,212.56,0,0,0,81.4,341.91h22.08A4.74,4.74,0,0,0,108.21,337.17Z"/>
          <path className="cls-1"
                d="M408.58,54v-.12L364,39.52a411.93,411.93,0,0,0-252.33,0l-24.71,8L72.22,51.81,45.63,60.05V85.53h57.85a31.42,31.42,0,0,1,31.37,31.37v72.6a24.24,24.24,0,0,0,24.22,24.22v26.64a24.24,24.24,0,0,0-24.22,24.22v72.59a31.41,31.41,0,0,1-31.37,31.37h-1.21A208.62,208.62,0,0,0,164.33,415l19.91,9.42,2.35,1.28,45.72,22,5.29,2.65,46.6-22v-.09l27.56-13.15A209.67,209.67,0,0,0,374,368.54H372.9a31.41,31.41,0,0,1-31.37-31.37V264.58a24.24,24.24,0,0,0-24.22-24.22V213.72a24.24,24.24,0,0,0,24.22-24.22V116.9A31.42,31.42,0,0,1,372.9,85.53h57.85V60.86ZM290.76,223.09c-8.86,37.63-23.36,55.24-35.68,72.77L286,332.94H190.44l30.9-37c-12.33-17.53-26.85-35.15-35.72-72.82-6.38-27.24-7.85-60.89-7.85-78.69H298.61C298.61,162.2,297.14,195.85,290.76,223.09Z"/>
          <polygon className="cls-1"
                   points="229.55 203.33 205.5 203.33 224.99 217.83 217.01 244.82 238.09 227.6 259.08 244.82 250.82 218.2 270.88 203.33 245.93 203.33 237.52 177.86 229.55 203.33"/>
          <path className="cls-2"
                d="M588.7,182.92a60.14,60.14,0,0,0-12.22-5.49A136.93,136.93,0,0,0,563,173.8q-6.79-1.47-12.37-2.94a24.9,24.9,0,0,1-9-4.1,8.35,8.35,0,0,1-3.4-7,10.07,10.07,0,0,1,2.09-6.18,13.82,13.82,0,0,1,6.57-4.41,35.89,35.89,0,0,1,11.91-1.62,56.94,56.94,0,0,1,14.84,2.09,63.06,63.06,0,0,1,15,6.26l7.88-18.87A59.69,59.69,0,0,0,579.19,130,84,84,0,0,0,559,127.49q-15.3,0-25.43,4.56T518.5,144.26a30.56,30.56,0,0,0-5,16.93q0,8.51,3.33,14.15a26,26,0,0,0,9,9.13A53.63,53.63,0,0,0,538.22,190q6.72,2.08,13.37,3.56t12.29,3.09a28.14,28.14,0,0,1,9,4.18,8.17,8.17,0,0,1,3.4,6.88,8.92,8.92,0,0,1-2.16,5.95,14.92,14.92,0,0,1-6.73,4.1,38.27,38.27,0,0,1-12,1.54,61,61,0,0,1-19.33-3.17,59,59,0,0,1-16.39-8.11l-8.51,18.86q7.28,5.57,19.25,9.05a89.51,89.51,0,0,0,25,3.47q15.3,0,25.51-4.63t15.23-12.22a29.57,29.57,0,0,0,5-16.7q0-8.34-3.49-14A26.39,26.39,0,0,0,588.7,182.92Z"/>
          <path className="cls-2"
                d="M681.67,134.06q-10.51-4.71-25-4.71H609.77V237.58H635V207.74h21.65q14.53,0,25-4.8a37.24,37.24,0,0,0,16.23-13.53q5.73-8.73,5.72-20.95,0-12.06-5.72-20.87A36.59,36.59,0,0,0,681.67,134.06Zm-9.13,48.32q-5.87,5-17.31,5H635V149.75h20.26q11.43,0,17.31,4.87t5.88,13.84Q678.42,177.43,672.54,182.38Z"/>
          <path className="cls-2"
                d="M811.26,143.26a56.28,56.28,0,0,0-18.79-11.67,65.68,65.68,0,0,0-23.58-4.1,64.62,64.62,0,0,0-23.58,4.17,57.67,57.67,0,0,0-18.79,11.75,53.49,53.49,0,0,0-12.44,17.71,58.61,58.61,0,0,0,0,44.61,53.27,53.27,0,0,0,12.44,17.78,57.34,57.34,0,0,0,18.87,11.75,69.15,69.15,0,0,0,47.08.08,56.31,56.31,0,0,0,18.79-11.68,52.88,52.88,0,0,0,12.45-17.86,59.12,59.12,0,0,0,0-44.76A53.15,53.15,0,0,0,811.26,143.26Zm-11.06,54.2a33.52,33.52,0,0,1-7.19,11,32.36,32.36,0,0,1-10.74,7.27,34.41,34.41,0,0,1-13.38,2.55,34.94,34.94,0,0,1-13.45-2.55,31.52,31.52,0,0,1-10.75-7.27,34.13,34.13,0,0,1-7.11-11,39.68,39.68,0,0,1,0-28,34.17,34.17,0,0,1,7.11-11,31.66,31.66,0,0,1,10.75-7.27,34.94,34.94,0,0,1,13.45-2.55,34.41,34.41,0,0,1,13.38,2.55A32.52,32.52,0,0,1,793,158.49a33.57,33.57,0,0,1,7.19,11,39.53,39.53,0,0,1,0,28Z"/>
          <path className="cls-2"
                d="M906.75,202.75h.08a36,36,0,0,0,16.25-13.33q5.74-8.74,5.74-21c0-8-1.91-15-5.74-20.83A36.4,36.4,0,0,0,906.83,134c-7-3.08-15.33-4.67-25-4.67H818.49l11,20.42h51c7.66,0,13.41,1.58,17.33,4.83s5.83,7.91,5.83,13.83-1.92,10.66-5.83,13.91-9.67,5-17.33,5H834.93v50.24h25.24V207.25h22.75l21,30.33h26.91Z"/>
          <path className="cls-2"
                d="M1338.27,202.75h.08a35.9,35.9,0,0,0,16.24-13.33q5.76-8.74,5.75-21c0-8-1.91-15-5.75-20.83A36.32,36.32,0,0,0,1338.35,134c-7-3.08-15.33-4.67-25-4.67H1250l11,20.42h51c7.66,0,13.41,1.58,17.33,4.83s5.83,7.91,5.83,13.83-1.92,10.66-5.83,13.91-9.67,5-17.33,5h-45.49v50.24h25.24V207.25h22.75l21,30.33h26.91Z"/>
          <polygon className="cls-2"
                   points="931.75 149.75 966.39 149.75 966.39 237.58 991.44 237.58 991.44 149.75 1026.08 149.75 1026.08 129.34 931.75 129.34 931.75 149.75"/>
          <path className="cls-2"
                d="M1104.62,182.92a60.05,60.05,0,0,0-12.21-5.49A137.87,137.87,0,0,0,1079,173.8q-6.79-1.47-12.36-2.94a24.74,24.74,0,0,1-9-4.1,8.35,8.35,0,0,1-3.4-7,10.06,10.06,0,0,1,2.08-6.18,13.82,13.82,0,0,1,6.57-4.41,35.89,35.89,0,0,1,11.91-1.62,56.94,56.94,0,0,1,14.84,2.09,63.06,63.06,0,0,1,15,6.26l7.89-18.87a59.87,59.87,0,0,0-17.4-7.11,84,84,0,0,0-20.18-2.47q-15.3,0-25.43,4.56t-15.08,12.21a30.56,30.56,0,0,0-5,16.93q0,8.51,3.33,14.15a26,26,0,0,0,9,9.13,53.63,53.63,0,0,0,12.37,5.56q6.72,2.08,13.37,3.56t12.29,3.09a28,28,0,0,1,9,4.18,8.14,8.14,0,0,1,3.4,6.88,8.92,8.92,0,0,1-2.16,5.95,14.92,14.92,0,0,1-6.73,4.1,38.27,38.27,0,0,1-12,1.54,61,61,0,0,1-19.33-3.17,59.16,59.16,0,0,1-16.39-8.11l-8.51,18.86q7.27,5.57,19.25,9.05a89.51,89.51,0,0,0,25,3.47q15.3,0,25.51-4.63t15.23-12.22a29.57,29.57,0,0,0,5-16.7q0-8.34-3.48-14A26.5,26.5,0,0,0,1104.62,182.92Z"/>
          <path className="cls-2"
                d="M1239.25,182.23c-1-.44-2.19-.64-3.26-1a27,27,0,0,0,10.44-9,26,26,0,0,0,4.18-14.77,24.34,24.34,0,0,0-10.28-20.41q-10.29-7.73-30.69-7.72h-52.89V237.58h56q21.33,0,32.31-7.81t11-21.72q0-9.44-4.48-15.93A28.22,28.22,0,0,0,1239.25,182.23Zm-32.71-34q9.14,0,14,3.09t4.87,9.58q0,6.51-4.87,9.67t-14,3.17h-24.89V148.21Zm19.18,67.26q-5.1,3.24-14.69,3.25h-29.38V192H1211q9.59,0,14.69,3.25t5.1,10.21C1230.82,210,1229.12,213.31,1225.72,215.47Z"/>
          <path className="cls-2"
                d="M1437.23,129.35h-24.74l-48.07,108.23h25.66l7.83-18.83,1.83-4.33,25.08-60.32,25.07,60.32,3.75,9.08,5.92,14.08h26.24Z"/>
          <path className="cls-2"
                d="M1514.74,158.41a32.46,32.46,0,0,1,11-7.19,36.91,36.91,0,0,1,13.91-2.55,34.42,34.42,0,0,1,14.92,3.25,36.68,36.68,0,0,1,12.3,9.59l16.23-14.85a49.45,49.45,0,0,0-19.17-14.3,68.72,68.72,0,0,0-48.79-.77,55.54,55.54,0,0,0-18.71,11.67A53.63,53.63,0,0,0,1484.05,161a59.2,59.2,0,0,0,0,44.84,53.92,53.92,0,0,0,12.29,17.78A54.63,54.63,0,0,0,1515,235.34a68.72,68.72,0,0,0,48.94-.85,50.14,50.14,0,0,0,19.17-14.38l-16.23-14.85a37.72,37.72,0,0,1-12.3,9.67,33.76,33.76,0,0,1-14.92,3.32,36.91,36.91,0,0,1-13.91-2.55,32.14,32.14,0,0,1-18.17-18.17,40.09,40.09,0,0,1,0-28.14A32.55,32.55,0,0,1,1514.74,158.41Z"/>
          <polygon className="cls-2"
                   points="1691.34 129.34 1663.5 129.34 1615.57 179.85 1615.57 129.34 1590.68 129.34 1590.68 237.58 1615.57 237.58 1615.57 210.1 1629.94 195.09 1664.74 237.58 1694.12 237.58 1646.6 177.65 1691.34 129.34"/>
          <polygon className="cls-2"
                   points="1725.93 192.43 1776.18 192.43 1776.18 172.79 1725.93 172.79 1725.93 149.74 1782.84 149.74 1782.84 129.34 1701.04 129.34 1701.04 237.58 1784.85 237.58 1784.85 217.48 1725.93 217.48 1725.93 192.43"/>
          <polygon className="cls-2"
                   points="1790.05 129.34 1790.05 149.75 1824.68 149.75 1824.68 237.58 1849.73 237.58 1849.73 149.75 1884.37 149.75 1884.37 129.34 1790.05 129.34"/>
          <path className="cls-1"
                d="M1235,272.59a20.19,20.19,0,0,0-9.18-7.75,35.15,35.15,0,0,0-14.36-2.68h-35.41l6.31,10H1211c5.17,0,9,1.07,11.59,3.21s3.92,5.35,3.92,9.27-1.24,7-3.92,9.27-6.42,3.21-11.59,3.21h-25.41v28.18h11.68V307h14.26a35.15,35.15,0,0,0,14.36-2.68,21.34,21.34,0,0,0,9.18-7.75,23.45,23.45,0,0,0,0-24Z"/>
          <path className="cls-1"
                d="M1311.58,270.44a31.76,31.76,0,0,0-10.75-6.75,37.45,37.45,0,0,0-13.52-2.38,36.87,36.87,0,0,0-13.6,2.43,32.85,32.85,0,0,0-10.8,6.79,30.92,30.92,0,0,0-7.12,10.27,34.18,34.18,0,0,0,0,25.76,31.28,31.28,0,0,0,7.12,10.31,32.43,32.43,0,0,0,10.85,6.85,39.21,39.21,0,0,0,27.07,0,31.3,31.3,0,0,0,17.82-17.11,34.38,34.38,0,0,0,0-25.89A30.68,30.68,0,0,0,1311.58,270.44Zm-3.87,32.2a21.51,21.51,0,0,1-4.63,7,20.88,20.88,0,0,1-7,4.59,23.27,23.27,0,0,1-8.79,1.62,23.91,23.91,0,0,1-8.87-1.62,20.23,20.23,0,0,1-7.06-4.64,22.22,22.22,0,0,1-4.69-7.07,22.67,22.67,0,0,1,4.69-24.72,20.23,20.23,0,0,1,7.06-4.64,23.91,23.91,0,0,1,8.87-1.62,23.27,23.27,0,0,1,8.79,1.62,21.12,21.12,0,0,1,7,4.6,20.25,20.25,0,0,1,4.64,7,23.78,23.78,0,0,1,1.62,8.91A23.23,23.23,0,0,1,1307.71,302.64Z"/>
          <path className="cls-1"
                d="M1394.28,270.44a31.9,31.9,0,0,0-10.76-6.75,37.43,37.43,0,0,0-13.51-2.38,36.83,36.83,0,0,0-13.6,2.43,32.9,32.9,0,0,0-10.81,6.79,30.74,30.74,0,0,0-7.11,10.27,34.18,34.18,0,0,0,0,25.76,31.11,31.11,0,0,0,7.11,10.31,32.43,32.43,0,0,0,10.85,6.85,39.21,39.21,0,0,0,27.07,0,31.28,31.28,0,0,0,17.83-17.11,34.51,34.51,0,0,0,0-25.89A31,31,0,0,0,1394.28,270.44Zm-3.87,32.2a21.38,21.38,0,0,1-4.64,7,20.78,20.78,0,0,1-7,4.59,23.21,23.21,0,0,1-8.78,1.62,23.87,23.87,0,0,1-8.87-1.62,20.18,20.18,0,0,1-7.07-4.64,22.3,22.3,0,0,1-6.4-15.9,22.29,22.29,0,0,1,1.71-8.86,22.61,22.61,0,0,1,4.69-7,20.18,20.18,0,0,1,7.07-4.64,23.87,23.87,0,0,1,8.87-1.62,23.21,23.21,0,0,1,8.78,1.62,21.26,21.26,0,0,1,7,4.6,20.37,20.37,0,0,1,4.63,7,23.78,23.78,0,0,1,1.62,8.91A23.23,23.23,0,0,1,1390.41,302.64Z"/>
          <polygon className="cls-1"
                   points="1434.87 262.2 1423.16 262.2 1423.16 325.25 1467.83 325.25 1467.83 315.34 1434.87 315.34 1434.87 262.2"/>
          <path className="cls-1"
                d="M1533.9,299.26a15.5,15.5,0,0,0-5.36-5.13,34.81,34.81,0,0,0-7.3-3.15q-4-1.22-8-2.12c-2.71-.6-5.15-1.23-7.34-1.89a14.43,14.43,0,0,1-5.32-2.75,5.85,5.85,0,0,1-2-4.63,7.16,7.16,0,0,1,1.39-4.28,9.49,9.49,0,0,1,4.46-3.11,22.22,22.22,0,0,1,7.84-1.17,32.85,32.85,0,0,1,8.65,1.22,34.81,34.81,0,0,1,8.73,3.73l3.69-9.09a32.77,32.77,0,0,0-9.73-4.15,44.84,44.84,0,0,0-11.25-1.43q-8.65,0-14.28,2.56a18.91,18.91,0,0,0-8.37,6.84,17.1,17.1,0,0,0-2.75,9.41,14.41,14.41,0,0,0,7.39,13.47,35.93,35.93,0,0,0,7.34,3.2q4,1.26,8,2.11t7.3,1.89a15.11,15.11,0,0,1,5.35,2.8,5.75,5.75,0,0,1,2.08,4.63,6.63,6.63,0,0,1-1.45,4.19,9.57,9.57,0,0,1-4.5,2.93,24.58,24.58,0,0,1-8,1.08,34,34,0,0,1-11.44-2,32.61,32.61,0,0,1-9.37-5l-4.05,9.1a32,32,0,0,0,10.76,5.45,46.64,46.64,0,0,0,14.1,2.12q8.64,0,14.31-2.57a18.88,18.88,0,0,0,8.43-6.85,16.89,16.89,0,0,0,2.74-9.32A15,15,0,0,0,1533.9,299.26Z"/>
        </svg>
      </>
  );
}
