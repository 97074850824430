// project-imports
import widget from './widget';
import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [widget, support]
};

export default menuItems;
