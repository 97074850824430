import supabase from "../services/useSupabase";

function filterUpcomingTournaments(data) {
    const today = new Date().toISOString(); // Get today's date in ISO format
    return data.filter(item =>
        item.tournament_dates.some(
            dateObj => dateObj.date_type === "start" && dateObj.date > today
        )
    );
}

async function getTournaments() {
    const {data} = await supabase
        .from("tournaments")
        .select("*,tournament_dates!inner(date, date_type)")
    return filterUpcomingTournaments(data);
}

export const poolCreateLoader = async ({request, params}) => {
    return getTournaments()
}
