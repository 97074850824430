import useSWR, {mutate} from 'swr';
import {useMemo} from 'react';

// Project-imports
import supabase from "../services/useSupabase";
import {IoAmericanFootballSharp} from "react-icons/io5";
import {TbOlympics} from "react-icons/tb";
import {PiSoccerBallFill} from "react-icons/pi";
import {FaBasketball} from "react-icons/fa6";

const initialState = {
    isDashboardDrawerOpened: false,
    isComponentDrawerOpened: true
};

export const endpoints = {
    key: 'api/menu',
    master: 'master',
    dashboard: '/dashboard' // server URL
};

export const icons = {
    football: IoAmericanFootballSharp,
    default: TbOlympics,
    soccer: PiSoccerBallFill,
    basketball: FaBasketball,
};

const getTournamentStartStatus = (dates) => {
    // Find the start date entry
    const startDateEntry = dates.find(date => date.date_type === 'start');

    if (!startDateEntry) {
        return null;
    }

    // Convert the start date string to Date object
    const startDate = new Date(startDateEntry.date);
    const currentDate = new Date();

    // Check if tournament has started
    if (currentDate >= startDate) {
        return {
            label: 'Started',
            color: 'error',
            variant: 'outlined',
            size: 'small'
        };
    }

    return {
        label: 'Not Started',
        color: 'success',
        variant: 'outlined',
        size: 'small'
    };
};
const transformToMenu = (data) => {
    const groupedByTournament = data.reduce((acc, entry) => {
        const tournamentName = entry.tournaments.name;
        const poolName = entry.tournament_pools.name;

        if (!acc[tournamentName]) {
            acc[tournamentName] = {};
        }
        if (!acc[tournamentName][poolName]) {
            acc[tournamentName][poolName] = [];
        }
        acc[tournamentName][poolName].push(entry);
        return acc;
    }, {});
    const entries = {
        id: 'entries',
        title: 'My Pools',
        type: 'group',
        children: Object.entries(groupedByTournament).map(([tournamentName, entries]) => {
            let tournamentType = 'default';
            let tournamentDates = [];
            Object.entries(entries).map(([poolName, entriesOfPool]) => {
                tournamentType = entriesOfPool[0].tournaments.type
                tournamentDates = entriesOfPool[0].tournaments.tournament_dates
            })
            return {
                id: `tournament-${tournamentName}`,
                title: tournamentName,
                type: 'collapse',
                icon: icons[tournamentType],
                children: Object.entries(entries).map(([poolName, entriesOfPool]) => ({
                    id: poolName + '-pool',
                    title: poolName,
                    type: 'collapse',
                    url: `/pools/${groupedByTournament[tournamentName][poolName][0].tournament_pools.id}`,
                    children: entriesOfPool.map(entry => ({
                        id: entry.pool_id + entry.id,
                        title: entry.name,
                        type: 'collapse',
                        url: `/pools/${entry.pool_id}`,
                        chip: getTournamentStartStatus(tournamentDates),
                        children: [
                            {
                                id: `pools/${entry.pool_id}/entry/${entry.id}`,
                                title: 'Bracket',
                                type: 'item',
                                url: `/pools/${entry.pool_id}/entry/${entry.id}`,
                                breadcrumbs: true,
                                chip: getTournamentStartStatus(tournamentDates)
                            },
                            {
                                id: `standings/${entry.pool_id}`,
                                title: 'Standings',
                                type: 'item',
                                url: `/standings/${entry.pool_id}`,
                                breadcrumbs: true,
                                search: 'chips, tags, ',
                            }
                        ]
                    }))
                }))
            }
        })
    };
    return entries
};


export function useGetMenu() {
    const {data, error, isValidating} = useSWR(endpoints.key + endpoints.dashboard, async () => {
        const user = await supabase.auth.getUser()
        const {
            data,
            error
        } = await supabase.from('tournament_pool_entries').select('*,tournaments(*,tournament_dates(*)),tournament_pools(*)').eq('user_id', user.data.user.id);
        if (error) throw error;
        return transformToMenu(data);
    }, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const memoizedValue = useMemo(
        () => ({
            menu: data,
            menuLoading: !data && !error,
            menuError: error,
            menuValidating: isValidating,
            menuEmpty: !data?.length
        }),
        [data, error, isValidating]
    );

    return memoizedValue;
}

export function useGetMenuMaster() {
    const {data, isLoading} = useSWR(endpoints.key + endpoints.master, () => initialState, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    const memoizedValue = useMemo(
        () => ({
            menuMaster: data,
            menuMasterLoading: isLoading
        }),
        [data, isLoading]
    );

    return memoizedValue;
}

export function handlerComponentDrawer(isComponentDrawerOpened) {
    // to update local state based on key

    mutate(
        endpoints.key + endpoints.master,
        (currentMenuMaster) => {
            return {...currentMenuMaster, isComponentDrawerOpened};
        },
        false
    );
}

export function handlerDrawerOpen(isDashboardDrawerOpened) {
    // to update local state based on key

    mutate(
        endpoints.key + endpoints.master,
        (currentMenuMaster) => {
            return {...currentMenuMaster, isDashboardDrawerOpened};
        },
        false
    );
}
