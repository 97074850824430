import {createClient} from "@supabase/supabase-js";

const supabase = createClient(
    process.env.NODE_ENV === 'production'
        ? 'https://redebiwzkmktwlepmkrv.supabase.co'
        : 'https://redebiwzkmktwlepmkrv.supabase.co',
    process.env.NODE_ENV === 'production'
        ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJlZGViaXd6a21rdHdsZXBta3J2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk3MDg1MzQsImV4cCI6MjAzNTI4NDUzNH0.b5tjbfKsXlFl_JO47UKAGzmo_j9np9tPl0TkEfR9Cn4'
        : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJlZGViaXd6a21rdHdsZXBta3J2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk3MDg1MzQsImV4cCI6MjAzNTI4NDUzNH0.b5tjbfKsXlFl_JO47UKAGzmo_j9np9tPl0TkEfR9Cn4'
)

export default supabase;
