import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

// material-ui
import {useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import MoreIcon from 'components/@extended/MoreIcon';
import IconButton from 'components/@extended/IconButton';

import defaultImages from 'assets/images/users/default.png';

// assets
import {icons} from "../../api/menu";
import hdate from "human-date";
import {Button} from "@mui/material";

// ==============================|| USER PROFILE - TABS ||============================== //

export default function AvailableTournament({focusInput, tournament}) {
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [avatar, setAvatar] = useState(defaultImages);

    useEffect(() => {
        if (selectedImage) {
            setAvatar(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Icon = icons[tournament.type] || icons.default;
    return (
        <MainCard>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton
                            variant="light"
                            color="secondary"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            sx={{transform: 'rotate(90deg)'}}
                        >
                            <MoreIcon/>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby': 'basic-button'}}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        >
                            <MenuItem
                                component={Link}
                                to={`/pools/start/${tournament.id}`}
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Create Pool
                            </MenuItem>
                        </Menu>
                    </Stack>
                    <Stack spacing={2.5} alignItems="center">
                        <Avatar alt="Avatar 1" sx={{width: 50, height: 50, border: '1px dashed'}}>
                            <Icon />
                        </Avatar>
                        {icons[tournament.type]}
                        <TextField
                            type="file"
                            id="change-avtar"
                            placeholder="Outlined"
                            variant="outlined"
                            sx={{display: 'none'}}
                            onChange={(e) => setSelectedImage(e.target.files?.[0])}
                        />
                        <Stack spacing={0.5} alignItems="center">
                            <Typography variant="h5">
                                {tournament.name}
                            </Typography>
                            <Typography color="secondary" align="justify">
                                {tournament.description}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item sm={3} sx={{display: {sm: 'block', md: 'none'}}}/>
                <Grid item xs={12} sm={6} md={12}>
                    <Stack direction="column" justifyContent="space-around" alignItems="center">
                        <Stack spacing={0.5} alignItems="center">
                            <Typography variant="h5">Dates</Typography>
                            <Typography color="secondary">
                                {hdate.prettyPrint(tournament.tournament_dates.find(date => date.date_type === 'start')?.date)} - {hdate.prettyPrint(tournament.tournament_dates.find(date => date.date_type === 'end')?.date)}
                            </Typography>
                        </Stack>
                        <Stack spacing={0.5} alignItems="center" sx={{mt: 2}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                fullWidth
                                to={`/pools/start/${tournament.id}`}>
                                Create Pool
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
}

AvailableTournament.propTypes = {focusInput: PropTypes.func};
