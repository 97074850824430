import { createContext, useState, useEffect, useContext } from 'react';
import supabase from "./services/useSupabase";

const AuthContext = createContext({
    user: null
});

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { data: listener } = supabase.auth.onAuthStateChange(
            (_event, session) => {
                setSession(session);
                setUser(session?.user || null);
                setIsLoggedIn(session?.user ? true : false);
                setLoading(false);
            }
        );
        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    // In case we want to manually trigger a signIn (instead of using Auth UI)
    const signIn = async () => {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: { skipBrowserRedirect: false },
        });
        return { data, error };
    };

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (!error) {
            setUser(null);
            setIsLoggedIn(false);
            setSession(null);
        }
        return { error };
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut }}>
            {!loading ? children : `Loading...`}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
