import supabase from "../services/useSupabase";
import {jwtDecode} from "jwt-decode";
import {redirect} from "react-router-dom";

export const superAdminLoader = async ({request, params}) => {
    const user = await supabase.auth.getSession();
    const session = user?.data?.session
    if (!session) {
        return redirect('/');
    }
    const jwt = jwtDecode(session.access_token);
    if (jwt?.user_role !== 'super_admin') {
        return redirect('/');
    }

    const tournaments = await  supabase.from('tournaments').select('*')
    return {
        tournaments: tournaments.data
    }
}
