import supabase from "../../services/useSupabase";
import {redirect} from "react-router-dom";
import {INVITES_KEY} from "../../consts";
import {isAllowedToInvite} from "./action";
import {hasTournamentStarted} from "../../db/hasTournamentStarted";
import {fetchCollegeLogos} from "../entry/functions/fetchCollegeLogos";

async function getPoolEntries(poolID, userID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_entries')
        .select("*,tournament_pool_picks(entry_id,round,tournament_teams(*))")
        .eq('pool_id', poolID)
        .eq('user_id', userID)
    if (error) {
        console.log(error);
        throw new Error('Could not get pool entries');
    }
    return data;
}

const handlePoolIDStorage = (poolID) => {
    try {
        const currentPoolIDs = JSON.parse(localStorage.getItem(INVITES_KEY)) || [];
        if (!currentPoolIDs.includes(poolID)) currentPoolIDs.push(poolID);
        localStorage.setItem(INVITES_KEY, JSON.stringify(currentPoolIDs));
    } catch (e) {
        localStorage.setItem(INVITES_KEY, JSON.stringify([poolID]));
    }
};

async function getParticipants(poolID) {
    const {
        data,
        error
    } = await supabase.from('tournament_pool_entries_by_user_three')
        .select("*")
        .eq('pool_id', poolID)
        .order('name', {ascending: true})
    if (error) {
        return []
    }
    return data;
}

export const loader = async ({params}) => {
    const user = await supabase.auth.getUser()
    if (params?.poolID) {
        handlePoolIDStorage(params.poolID);
    }
    if (!user.data.user) {
        return redirect('/login');
    }
    const {poolID} = params;
    const entries = await getPoolEntries(poolID, user.data.user.id)
    const participants = await getParticipants(poolID);
    const {
        data,
        error
    } = await supabase.from('tournament_pools').select('*,tournaments(*,tournament_teams(*))').eq('id', poolID).single()
    if (error) {
        throw new Error('Pool Not Found, if you were given this link, make sure your email has access to this pool (contact the person who invited you).');
    }
    return {
        participants: participants,
        collegeLogos: await fetchCollegeLogos(),
        entries: entries,
        isAllowedToInvite: await isAllowedToInvite(poolID, user.data.user.id),
        hasTournamentStarted: await hasTournamentStarted(poolID),
        poolInfo: data,
    }
}
