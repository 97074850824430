import {createRoot} from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import Root from './routes/Root';
import {Login} from './routes/Login';
import {ErrorPage} from "./ErrorPage";
import AuthProvider from "./AuthProvider";
import PoolsIndex from "./pools/home";
import Dashboard from "./dashboard";
import CreatePool from "./pools/create";
import StartPool from "./pools/start";
import {loader} from "./pools/home/loader";
import {action} from "./pools/home/action";
import {poolCreateLoader} from "./pools/poolCreateLoader";
import {poolStartTournamentAction} from "./pools/poolStartTournamentAction";
import {standingsAction} from "./pools/standingsAction";
import {standingsLoader} from "./pools/standingsLoader";
import Standings from "./pools/standings";
import {poolIdEntryIdAction} from "./pools/entry/poolIdAction";
import {poolIdEntryIdLoader} from "./pools/entry/poolIdLoader";
import PoolEntryIndex from "./pools/entry";
import {dashboardLoader} from "./dashboard/dashboardLoader";
import AppAppBar from "./components/AppAppBar";
import {Container} from "@mui/material";
import {loginLoader} from "./routes/loginLoader";
import {SuperAdminIndex} from "./superAdmin";
import {superAdminLoader} from "./superAdmin/loader";
import {Tournament} from "./superAdmin/tournaments";
import {tournamentLoader} from "./superAdmin/tournaments/loader";
import {tournamentAction} from "./superAdmin/tournaments/action";
import {ConfigProvider} from "./contexts/ConfigContext";
import ThemeCustomization from "./themes";

// fonts
import 'assets/fonts/inter/inter.css';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/700.css';

import {SimpleLayoutType} from "./config";
import SimpleLayout from "./layout/Simple";

import Loadable from 'components/Loadable';
import {lazy} from "react";
import MainRoutes from "./routes/MainRoutes";

import LoginRoutes from "./routes/LoginRoutes";

const PagesLanding = Loadable(lazy(() => import('pages/landing')));

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <SimpleLayout layout={SimpleLayoutType.LANDING}/>,
            children: [
                {
                    index: true,
                    element: <PagesLanding/>
                }
            ]
        },
        MainRoutes,
        LoginRoutes
    ],
    {basename: ""}
);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <AuthProvider>
        <ThemeCustomization>
            <RouterProvider router={router}/>
        </ThemeCustomization>
    </AuthProvider>
);
