// third-party

// assets
import { Book1, I24Support, Security, MessageProgramming, DollarSquare, Airplane } from 'iconsax-react';

// type

// icons
const icons = {
  page: Book1,
  authentication: Security,
  maintenance: MessageProgramming,
  pricing: DollarSquare,
  contactus: I24Support,
  landing: Airplane
};

// ==============================|| MENU ITEMS - PAGES ||============================== //

const superAdmin = {
  id: 'super-admin',
  title: 'Super Admin',
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'tournaments',
      title: 'Tournaments',
      type: 'item',
      breadcrumbs: false,
      url: '/superAdmin',
      icon: icons.authentication
    },
  ]
};

export default superAdmin;
