import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

// Custom hook to handle auth state
export const useAuthRole = (supabase) => {
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Initial check for existing session
        const checkCurrentSession = async () => {
            try {
                const { data: { session } } = await supabase.auth.getSession();
                if (session) {
                    const jwt = jwtDecode(session.access_token);
                    setUserRole(jwt?.user_role);
                }
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        // Run initial check
        checkCurrentSession();

        // Set up auth state listener
        const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                const jwt = jwtDecode(session.access_token);
                setUserRole(jwt?.user_role);
            } else {
                setUserRole(null);
            }
        });

        // Cleanup subscription on unmount
        return () => {
            subscription?.unsubscribe();
        };
    }, [supabase]);

    return { userRole, isLoading, error, isSuperAdmin: userRole === 'super_admin' };
};
