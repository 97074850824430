import {redirect} from "react-router-dom";
import supabase from "../../services/useSupabase";
import isSuperAdmin from "../functions/isSuperAdmin";

export const tournamentLoader = async ({request, params}) => {
    if (!isSuperAdmin()) {
        return redirect('/');
    }
    const {tournamentID} = params;
    if (!tournamentID) {
        return {
            tournament: {
                hasStarted: false,
                tournament_dates: [
                    {
                        date: new Date(),
                        date_type: 'start',
                        name: 'Start of the Tournament'
                    },
                    {
                        date: new Date(),
                        date_type: 'end',
                        name: 'End of the Tournament'
                    }
                ],
                tournament_teams: [],
                tournament_winners: [],
                tournament_scoring_settings: [],
            }
        }
    }
    const tournament = await supabase
        .from('tournaments')
        .select('*,tournament_winners(*),tournament_dates(*),tournament_teams(*),tournament_scoring_settings(*)')
        .order('starting_round', {ascending: true, foreignTable: 'tournament_teams'})
        .order('order', {ascending: true, foreignTable: 'tournament_teams'})
        .order('round', {ascending: true, foreignTable: 'tournament_winners'})
        .order('order', {ascending: true, foreignTable: 'tournament_winners'})
        .eq('id', tournamentID)
        .single()

    return {
        tournament: {
            ...tournament.data,
            hasStarted: new Date(tournament.data.tournament_dates.find(item => item.date_type === 'start').date) < new Date(),
        },
    }
}
