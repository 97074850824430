import PropTypes from 'prop-types';
import {Fragment, useEffect, useMemo, useState} from 'react';

// material-ui
import {alpha, useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';

// third-party
import {DndProvider} from 'react-dnd';
import {isMobile} from 'react-device-detect';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedMinMaxValues,
    getFacetedUniqueValues,
    getPaginationRowModel,
    getSortedRowModel,
    getGroupedRowModel,
    getExpandedRowModel,
    flexRender,
    useReactTable,
} from '@tanstack/react-table';
import { rankItem} from '@tanstack/match-sorter-utils';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';

import {
    DebouncedInput,
    EmptyTable,
    TablePagination,
    HeaderSort
} from 'components/third-party/react-table';


//assets
import {ArrowDown2, ArrowRight2, CloseCircle} from 'iconsax-react';
import StandingsExpandingDetails, {renderTeamGridItem} from "./components/StandingsExpandingDetails";

export const fuzzyFilter = (row, columnId, value, addMeta) => {
    // rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // store the ranking info
    addMeta(itemRank);

    // return if the item should be filtered in/out
    return itemRank.passed;
};

// ==============================|| REACT TABLE ||============================== //

function ReactTable({defaultColumns, data, setData, standingsData}) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [grouping, setGrouping] = useState([]);


    const [columns] = useState(() => [...defaultColumns]);

    const [columnOrder, setColumnOrder] = useState(
        // must start out with populated columnOrder so we can splice
        columns.map((column) => column.id)
    );

    const reorderRow = (draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
    };

    const [columnVisibility, setColumnVisibility] = useState({});

    const table = useReactTable({
        data,
        columns,
        defaultColumn: {},
        state: {
            rowSelection,
            columnFilters,
            globalFilter,
            sorting,
            grouping,
            columnOrder,
            columnVisibility
        },
        enableRowSelection: false,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onGroupingChange: setGrouping,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onColumnOrderChange: setColumnOrder,
        onColumnVisibilityChange: setColumnVisibility,
        getRowCanExpand: () => true,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        globalFilterFn: fuzzyFilter,
        getRowId: (row) => row.id.toString(), // good to have guaranteed unique row ids/keys for rendering
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    useEffect(() => setColumnVisibility({id: false, role: false, contact: false, country: false, progress: false}), []);

    const backColor = alpha(theme.palette.primary.lighter, 0.1);

    let headers = [];
    table.getVisibleLeafColumns().map(
        (columns) =>
            // @ts-ignore
            columns.columnDef.accessorKey &&
            headers.push({
                label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
                // @ts-ignore
                key: columns.columnDef.accessorKey
            })
    );

    return (
        <MainCard content={false}>
            <Stack
                direction={{xs: 'column', sm: 'row'}}
                spacing={2}
                justifyContent="space-between"
                sx={{padding: 2, ...(matchDownSM && {'& .MuiOutlinedInput-root, & .MuiFormControl-root': {width: '100%'}})}}
            >
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onFilterChange={(value) => setGlobalFilter(String(value))}
                    placeholder={`Search ${data.length} records...`}
                />

            </Stack>

            <ScrollX>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead className="sticky-header">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                                            Object.assign(header.column.columnDef.meta, {
                                                className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select'
                                            });
                                        }

                                        return (
                                            <TableCell
                                                key={header.id}
                                                {...header.column.columnDef.meta}
                                                onClick={header.column.getToggleSortingHandler()}
                                                {...(header.column.getCanSort() &&
                                                    header.column.columnDef.meta === undefined && {
                                                        className: 'cursor-pointer prevent-select'
                                                    })}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                                                        {header.column.getCanSort() &&
                                                            <HeaderSort column={header.column}/>}
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <Fragment key={row.id}>
                                        <TableRow row={row} reorderRow={reorderRow}>
                                            <>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                        <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </TableCell>
                                                    );
                                                })}
                                            </>
                                        </TableRow>
                                        {row.getIsExpanded() && !row.getIsGrouped() && (
                                            <TableRow sx={{
                                                bgcolor: backColor,
                                                '&:hover': {bgcolor: `${backColor} !important`}
                                            }}>
                                                <TableCell colSpan={row.getVisibleCells().length + 2}>
                                                    <StandingsExpandingDetails data={row.original} standingsData={standingsData}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Fragment>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={table.getAllColumns().length}>
                                        <EmptyTable msg="No Data"/>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider/>
                <Box sx={{p: 2}}>
                    <TablePagination
                        {...{
                            setPageSize: table.setPageSize,
                            setPageIndex: table.setPageIndex,
                            getState: table.getState,
                            getPageCount: table.getPageCount
                        }}
                    />
                </Box>
            </ScrollX>
        </MainCard>
    );
}

// ==============================|| REACT TABLE - UMBRELLA ||============================== //

export default function UmbrellaTable({standingsData}) {
    const [data, setData] = useState(() => {
        return standingsData.standings
    });

    const columns = useMemo(
        () => [
            {
                id: 'expander',
                enableGrouping: false,
                header: () => null,
                cell: ({row}) => {
                    return row.getCanExpand() ? (
                        <IconButton color={row.getIsExpanded() ? 'primary' : 'secondary'}
                                    onClick={row.getToggleExpandedHandler()} size="small">
                            {row.getIsExpanded() ? <ArrowDown2 size="32" variant="Outline"/> :
                                <ArrowRight2 size="32" variant="Outline"/>}
                        </IconButton>
                    ) : (
                        <IconButton color="secondary" size="small" disabled>
                            <CloseCircle/>
                        </IconButton>
                    );
                }
            },
            {
                id: 'id',
                title: 'Id',
                header: '#',
                accessorKey: 'id',
                dataType: 'text',
                enableColumnFilter: false,
                enableGrouping: false,
                meta: {className: 'cell-center'}
            },
            {
                id: 'winner',
                header: 'Winner',
                accessorKey: 'winner',
                enableColumnFilter: false,
                enableGrouping: false,
                cell: (cell) => {
                    return renderTeamGridItem(cell.getValue(), 0, standingsData.collegeLogos, standingsData.winners, 'winner', 2, standingsData.totalTeams, cell?.row?.original?.incorrectPicksTeams || [])
                },
                meta: {className: 'cell-center'},
            },
            {
                id: 'bracketName',
                header: 'Bracket Name',
                footer: 'Bracket Name',
                accessorKey: 'bracketName',
                dataType: 'text',
                enableGrouping: false
            },
            {
                id: 'points',
                header: 'Points',
                footer: 'Points',
                accessorKey: 'points',
                dataType: 'number',
                enableGrouping: false
            }
        ],
        []
    );

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <ReactTable {...{data, defaultColumns: columns, setData, standingsData}} />
        </DndProvider>
    );
}


ReactTable.propTypes = {defaultColumns: PropTypes.array, data: PropTypes.array, setData: PropTypes.any};
