import {Container} from "@mui/material";
import {useActionData, useLoaderData, useParams} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Stack
} from '@mui/material';
import {Delete, Add} from '@mui/icons-material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import {Form} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

export const Tournament = () => {
    const {tournamentID} = useParams();

    const data = useLoaderData();
    const tournament = data.tournament;
    const [formData, setFormData] = useState({
        name: '',
        hasStarted: false,
        type: '',
        tie_breaker: 0,
        tournament_dates: [],
        tournament_teams: [],
        tournament_scoring_settings: [],
        tournament_winners: [],
        description: '',
        tie_breaker_description: ''
    });

    const actionResult = useActionData();
    useEffect(() => {
        if (tournament) {
            setFormData({
                name: tournament.name,
                type: tournament.type,
                tie_breaker: tournament.tie_breaker,
                tournament_dates: tournament.tournament_dates,
                tournament_teams: tournament.tournament_teams,
                tournament_scoring_settings: tournament.tournament_scoring_settings,
                tournament_winners: tournament.tournament_winners,
                hasStarted: tournament.hasStarted,
                description: tournament.description,
                tie_breaker_description: tournament.tie_breaker_description
            });
        }
        if (actionResult && actionResult.success) {
            toast("Tournament updated successfully", {type: "success"});
        }
    }, [tournament, actionResult]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (index, value, dateType) => {
        const newDates = [...formData.tournament_dates];
        newDates[index] = {
            ...newDates[index],
            date: new Date(value).toISOString()
        };
        setFormData({
            ...formData,
            tournament_dates: newDates
        });
    };

    const handleTeamChange = (index, field, value) => {
        const newTeams = [...formData.tournament_teams];
        newTeams[index] = {
            ...newTeams[index],
            [field]: value
        };
        setFormData({
            ...formData,
            tournament_teams: newTeams
        });
    };

    const handleScoreSettingChange = (index, field, value) => {
        const newSettings = [...formData.tournament_scoring_settings];
        newSettings[index] = {
            ...newSettings[index],
            [field]: value
        };
        setFormData({
            ...formData,
            tournament_scoring_settings: newSettings
        });
    };

    const addTeam = () => {
        setFormData({
            ...formData,
            tournament_teams: [
                {
                    name: '',
                    rank: 0,
                    starting_round: 1,
                    order: formData.tournament_teams.length
                },
                ...formData.tournament_teams,
            ]
        });
    };

    const removeTeam = (index) => {
        const newTeams = formData.tournament_teams.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            tournament_teams: newTeams
        });
    };

    const addScore = () => {
        setFormData({
            ...formData,
            tournament_scoring_settings: [
                {
                    round: 0,
                    score: 0,
                },
                ...formData.tournament_scoring_settings,
            ]
        });
    };

    const removeScore = (index) => {
        const newScores = formData.tournament_scoring_settings.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            tournament_scoring_settings: newScores
        });
    };

    const addWinner = () => {
        setFormData({
            ...formData,
            tournament_winners: [
                ...formData.tournament_winners,
                {
                    order: formData.tournament_winners.length,
                    round: 1,
                    team_id: '',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                }
            ]
        });
    };

    const removeWinner = (index) => {
        const newWinners = formData.tournament_winners.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            tournament_winners: newWinners
        });
    };

    const handleWinnerChange = (index, field, value) => {
        const newWinners = [...formData.tournament_winners];
        newWinners[index] = {
            ...newWinners[index],
            [field]: field === 'round' || field === 'order' ? Number(value) : value,
            updated_at: new Date().toISOString()
        };
        setFormData({
            ...formData,
            tournament_winners: newWinners
        });
    };


    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ToastContainer/>
        <Container>
            <Paper sx={{p: 3, maxWidth: 800, mx: 'auto', mt: 3}}>
                <Form method="post" action={`/superAdmin/tournaments/${tournamentID}`}>
                    <input type="hidden" name="formData" value={JSON.stringify(formData)}/>
                    <Typography variant="h5" gutterBottom>
                        {tournamentID ? 'Edit' : 'Create'} Tournament
                    </Typography>

                    <Grid container spacing={3}>
                        {/* Basic Details */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                    label="Type"
                                >
                                    <MenuItem value="football">Football</MenuItem>
                                    <MenuItem value="basketball">Basketball</MenuItem>
                                    <MenuItem value="soccer">Soccer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Tie Breaker"
                                name="tie_breaker"
                                value={formData.tie_breaker}
                                onChange={handleChange}
                            />
                        </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                minRows={3}
                                label="Tie Breaker Description"
                                name="tie_breaker_description"
                                value={formData.tie_breaker_description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                label="Tournament Description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Grid>

                        {/* Tournament Dates */}
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Tournament Dates
                            </Typography>
                            {formData.tournament_dates.map((date, index) => (
                                <Box key={date.id} sx={{mb: 2}}>
                                    <DateTimePicker
                                        label={date.name}
                                        value={dayjs(date.date)}
                                        onChange={(newValue) => handleDateChange(index, newValue, date.name)}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                            ))}
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{mb: 2}}>
                                <Typography variant="h6">Tournament Winners</Typography>
                                <Button startIcon={<Add/>} onClick={addWinner}>
                                    Add Winner
                                </Button>
                            </Stack>
                            {formData.tournament_winners.map((winner, index) => (
                                <Box key={winner.id || index} sx={{mb: 2, p: 2, border: '1px solid #eee'}}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel>Team</InputLabel>
                                                <Select
                                                    value={winner.team_id}
                                                    onChange={(e) => handleWinnerChange(index, 'team_id', e.target.value)}
                                                    label="Team"
                                                >
                                                    {formData.tournament_teams.map((team) => (
                                                        <MenuItem key={team.id} value={team.id}>
                                                            {team.name} (Rank: {team.rank})
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="Round"
                                                value={winner.round}
                                                onChange={(e) => handleWinnerChange(index, 'round', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                label="Order"
                                                value={winner.order}
                                                onChange={(e) => handleWinnerChange(index, 'order', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <IconButton color="error" onClick={() => removeWinner(index)}>
                                                <Delete/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                        {/* Teams */}
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Teams</Typography>
                                {!formData.hasStarted && <Button startIcon={<Add/>} onClick={addTeam}>
                                    Add Team
                                </Button>}
                            </Stack>
                            <Typography variant="body2" color="textSecondary">
                                All the teams in the tournament. The order must start at 0. Total teams must be 12, 14, 16,
                                32, or 64.
                            </Typography>
                            {formData.tournament_teams.map((team, index) => (
                                <Box key={team.id || index} sx={{mb: 2, p: 2, border: '1px solid #eee'}}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                disabled={formData.hasStarted}
                                                fullWidth
                                                label="Team Name"
                                                value={team.name}
                                                onChange={(e) => handleTeamChange(index, 'name', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={formData.hasStarted}
                                                fullWidth
                                                type="number"
                                                label="Rank"
                                                value={team.rank}
                                                onChange={(e) => handleTeamChange(index, 'rank', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={formData.hasStarted}
                                                fullWidth
                                                type="number"
                                                label="Order"
                                                value={team.order}
                                                onChange={(e) => handleTeamChange(index, 'order', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={formData.hasStarted}
                                                fullWidth
                                                type="number"
                                                label="Starting Round"
                                                value={team.starting_round}
                                                onChange={(e) => handleTeamChange(index, 'starting_round', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <IconButton color="error" onClick={() => removeTeam(index)}>
                                                {!formData.hasStarted && <Delete/>}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>

                        {/* Scoring Settings */}
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Scoring Settings</Typography>
                                <Button startIcon={<Add/>} onClick={addScore}>
                                    Add Score
                                </Button>
                            </Stack>
                            <Typography variant="body2" color="textSecondary">
                                Scoring rounds must start at second round (round 2). The first round (round 1) is
                                reserved for the initial teams.
                            </Typography>
                            {formData.tournament_scoring_settings.map((setting, index) => (
                                <Box key={index} sx={{mb: 2, p: 2, border: '1px solid #eee'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                label={`Round Number`}
                                                type="number"
                                                value={setting.round}
                                                onChange={(e) => handleScoreSettingChange(index, 'round', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                label={`Score multiplier`}
                                                type="number"
                                                value={setting.score}
                                                onChange={(e) => handleScoreSettingChange(index, 'score', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <IconButton color="error" onClick={() => removeScore(index)}>
                                                <Delete/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                    <Box sx={{mt: 3}}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Form>
            </Paper>
        </Container>
    </LocalizationProvider>
}
