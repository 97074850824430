import * as Logos from 'react-nfl-logos';
// Extract the logo mapping into a constant
const teamLogoMap = {
    min: "MIN",
    minnesota: "MIN",
    atl: "ATL",
    atlanta: "ATL",
    gb: "GB",
    "green bay": "GB",
    ari: "ARI",
    arizona: "ARI",
    bal: "BAL",
    baltimore: "BAL",
    buf: "BUF",
    buffalo: "BUF",
    car: "CAR",
    carolina: "CAR",
    chi: "CHI",
    chicago: "CHI",
    cin: "CIN",
    cincinnati: "CIN",
    cle: "CLE",
    cleveland: "CLE",
    dal: "DAL",
    dallas: "DAL",
    den: "DEN",
    denver: "DEN",
    det: "DET",
    detroit: "DET",
    hou: "HOU",
    houston: "HOU",
    ind: "IND",
    indianapolis: "IND",
    jac: "JAX",
    jacksonville: "JAX",
    kc: "KC",
    "kansas city": "KC",
    lv: "LV",
    "las vegas": "LV",
    lac: "LAC",
    "los angeles chargers": "LAC",
    lar: "LAR",
    "los angeles rams": "LAR",
    mia: "MIA",
    miami: "MIA",
    ne: "NE",
    "new england": "NE",
    no: "NO",
    "new orleans": "NO",
    nyg: "NYG",
    "new york giants": "NYG",
    nyj: "NYJ",
    "new york jets": "NYJ",
    phi: "PHI",
    philadelphia: "PHI",
    pit: "PIT",
    pittsburgh: "PIT",
    sf: "SF",
    "san francisco": "SF",
    sea: "SEA",
    seattle: "SEA",
    tb: "TB",
    "tampa bay": "TB",
    ten: "TEN",
    tennessee: "TEN",
    wsh: "WAS",
    washington: "WAS"
};

// Helper function to get the logo name
const getLogoComponentByName = (name) => {
    const logoKey = teamLogoMap[name.toLowerCase()];
    return logoKey ? Logos[logoKey] : null;
};

// Refactored component
export const NFLLogos = ({ name }) => {
    const LogoComponent = getLogoComponentByName(name);
    return LogoComponent ? <LogoComponent size={50} /> : null;
};
