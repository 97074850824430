import supabase from "../../../services/useSupabase";
import {handleCardClick} from "./handleCardClick";

export const handlePrediction = async (team, opponent, tournamentID, isLastRound, round, bracket, setBracket, tournamentStarted, totalTeams) => {
    const data = await supabase.functions.invoke('predict', {
        body: {team, opponent, tournamentID}
    });
    if (!data.error) {
        handleCardClick(bracket, setBracket, isLastRound, data.data.winner, round, tournamentStarted, totalTeams)
    }
};
