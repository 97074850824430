import {Box, Grid, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {handleCardClick} from "./functions/handleCardClick";
import {getBackgroundColor} from "./functions/getBackgroundColor";
import TeamCard from "./TeamCard";
import TieBreaker from "./TieBreaker";
import {uuid} from "./functions/uuid";

const SixteenTeams = ({data, poolID, entryID, tournamentStarted, tieBreaker, entryName, totalTeams, tournamentTeams = []}) => {
    const [bracket, setBracket] = useState(data?.bracketStructure);
    const [inputValue, setInputValue] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        setInputValue(tieBreaker);
    }, [data]);


    return <Box sx={{
        ...(isMobile && { overflowX: 'auto' }),  // Only add horizontal scroll on mobile
        padding: '5px'  // Optional: add some padding on sides
    }}>
        <TieBreaker
            poolID={poolID}
            entryID={entryID}
            bracket={bracket}
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            tournamentStarted={tournamentStarted}
            entryName={entryName}
        />
        <Grid
            container
            sx={{
                width: 'max-content',  // Allow grid to expand beyond screen width
            }}
        >
            {Object.entries(bracket).map((([round, value], indexColum, array) => {
                const isLastRound = indexColum === array.length - 1;
                return <Grid key={uuid()} sx={{display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "15px"}}>
                    <Box display="flex" flexDirection="column" gap={1}>
                        {bracket[round].map((team, index) => (
                            <TeamCard
                                totalTeams={totalTeams}
                                key={uuid()}
                                team={team}
                                round={round}
                                isLastRound={isLastRound}
                                handleCardClick={() => {
                                    handleCardClick(bracket, setBracket, isLastRound, team, round, tournamentStarted, totalTeams)
                                }}
                                backgroundColor={getBackgroundColor(data.winners[round], round, team, tournamentTeams)}
                            />
                        ))}
                    </Box>
                </Grid>
            }))}
        </Grid>
    </Box>
        ;
};

export default SixteenTeams;
