import PropTypes from 'prop-types';
// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import {icons} from "../../../api/menu";
import {Box, Button, ButtonGroup, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import { GrTrophy } from "react-icons/gr";
import {LockOpen} from "@mui/icons-material";
import {Lock1} from "iconsax-react";
import Chip from "@mui/material/Chip";
import hdate from "human-date";
import {CgDetailsMore} from "react-icons/cg";
import {MdSportsKabaddi} from "react-icons/md";

// ============================|| STATISTICS - ROUND ICON CARD ||============================ //

export default function RoundIconCard({primary, secondary, content, iconPrimary, tournament, poolID, entryID, setIsLoading, privatePool, tournamentDates}) {
    const IconPrimary = icons[iconPrimary];
    const primaryIcon = iconPrimary ? <IconPrimary/> : null;
    const theme = useTheme();

    let color = 'primary.darker';
    let bgcolor = 'primary.lighter';
    switch (iconPrimary) {
        case 'football':
            color = 'primary.main';
            bgcolor = 'primary.lighter';
            break;
        case 'soccer':
            color = 'success.main';
            bgcolor = 'success.lighter';
            break;
        case 'basketball':
            color = 'warning.main';
            bgcolor = 'warning.lighter';
            break;
        default:
            break;
    }
    return (
        <MainCard>
            <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                <Chip
                    icon={privatePool ? <Lock1 /> : <LockOpen />}
                    label={privatePool ? "Private" : "Public"}
                    size="small"
                    sx={{
                        fontWeight: 600,

                        color: privatePool
                            ? theme.palette.success.darker
                            : theme.palette.error.darker,
                        '& .MuiChip-icon': {
                            fontSize: 16,
                            marginLeft: '8px',
                            color: 'inherit'
                        }
                    }}
                />
            </Box>
            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                <Grid item>
                    <Stack spacing={1}>
                        <Typography variant="h4" color="inherit">
                            {primary}
                        </Typography>
                        <Typography variant="h5">{secondary}</Typography>
                        <Typography variant="subtitle2" color="inherit">
                            {tournament}
                        </Typography>
                        <Typography variant="subtitle3" color="inherit">
                            {content}
                        </Typography>
                        <Typography color="secondary">
                            {hdate.prettyPrint(tournamentDates.find(date => date.date_type === 'start')?.date)} to {hdate.prettyPrint(tournamentDates.find(date => date.date_type === 'end')?.date)}
                        </Typography>
                        <Chip
                            sx={{
                                width: 'fit-content',
                            }}
                            size={'small'}
                            label={(() => {
                                const now = new Date();
                                const startDate = new Date(
                                    tournamentDates.find((date) => date.date_type === 'start')?.date
                                );
                                const endDate = new Date(
                                    tournamentDates.find((date) => date.date_type === 'end')?.date
                                );

                                if (now < startDate) return 'Not Started';
                                if (now > endDate) return 'Completed';
                                return 'In Progress';
                            })()}
                            color={(() => {
                                const now = new Date();
                                const startDate = new Date(
                                    tournamentDates.find((date) => date.date_type === 'start')?.date
                                );
                                const endDate = new Date(
                                    tournamentDates.find((date) => date.date_type === 'end')?.date
                                );

                                if (now < startDate) return 'default';
                                if (now > endDate) return 'success';
                                return 'primary';
                            })()}
                        />
                    </Stack>
                </Grid>
                <Grid item>
                    <Avatar variant="rounded" sx={{bgcolor, color}} size="lg">
                        {primaryIcon}
                    </Avatar>
                </Grid>
            </Grid>
            <Grid>
                <ButtonGroup
                    variant="contained"
                    sx={{
                        display: "flex",
                        mt: 3,
                        justifyContent: "center",
                        width: "100%", // Ensures buttons span the full width if needed
                    }}
                >
                    <Button
                        startIcon={<CgDetailsMore />}
                        component={Link}
                        to={`/pools/${poolID}`}
                        sx={{
                            textDecoration: "none",
                            fontWeight: 600,
                            width: "50%",
                            textAlign: "center",
                        }}
                    >
                        Pool
                    </Button>
                    <Button
                        onClick={() => setIsLoading(true)}
                        component={Link}
                        to={`/pools/${poolID}/entry/${entryID}`}
                        startIcon={<MdSportsKabaddi />}
                        sx={{
                            textDecoration: "none",
                            fontWeight: 600,
                            width: "50%",
                            textAlign: "center",
                        }}
                    >
                        Entry
                    </Button>
                    {new Date() > new Date(tournamentDates.find(date => date.date_type === 'start')?.date) && (
                        <Button
                            onClick={() => setIsLoading(true)}
                            component={Link}
                            to={`/standings/${poolID}`}
                            startIcon={<GrTrophy />}
                            sx={{
                                textDecoration: "none",
                                fontWeight: 600,
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            Standings
                        </Button>
                    )}
                </ButtonGroup>
            </Grid>
        </MainCard>
    );
}

RoundIconCard.propTypes = {
    poolID: PropTypes.string,
    entryID: PropTypes.string,
    primary: PropTypes.string,
    tournament: PropTypes.string,
    secondary: PropTypes.string,
    content: PropTypes.string,
    iconPrimary: PropTypes.any,
    color: PropTypes.string,
    bgcolor: PropTypes.string
};
