import {useActionData, useLoaderData, useParams} from 'react-router-dom';
import {Box, Grid} from "@mui/material";
import React, {useEffect} from "react";
import SixtyFourTeams from "./SixtyFourTeams";
import SixteenTeams from "./SixteenTeams";
import countryList from "country-list";
import ThirtyTwoTeams from "./ThirtyTwoTeams";
import TwelveTeams from "./TwelveTeams";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ProfileCard from "../../sections/apps/profiles/user/ProfileCard";

countryList.overwrite([{
    code: 'TR',
    name: 'Turkey'
}, {
    code: 'GB',
    name: 'England'
}])

const PoolEntryIndex = () => {
    const data = useLoaderData();
    const actionResult = useActionData();

    const {poolID, entryID} = useParams();
    const bracket = data?.bracketStructure || {}
    const totalTeams = data?.totalTeams

    useEffect(() => {
        if (actionResult && actionResult.success) {
            toast("Bracket updated successfully", {type: "success"});
        }
    }, [actionResult]);

    return (
        <Box sx={{paddingBottom: "120px"}}>
            <Grid item xs={12} mb={3}>
                <ProfileCard title={data.tournamentName} link={`/pools/${poolID}`} linkText={"Back to Pool"}/>
            </Grid>
            <ToastContainer/>
            {(() => {
                switch (totalTeams) {
                    case 12:
                    case 14:
                        return <TwelveTeams data={data} entryID={entryID} poolID={poolID}
                                            tournamentStarted={data.tournamentStarted}
                                            tournamentTeams={data.tournamentTeams}
                                            bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                            entryName={data.entry.name} totalTeams={totalTeams}
                                            userRole={data.userRole}
                                            tieBreakerDescription={data.tieBreakerDescription}
                                            collegeLogos={data.collegeLogos}/>;
                    case 16:
                        return <SixteenTeams data={data} entryID={entryID} poolID={poolID}
                                             tournamentStarted={data.tournamentStarted}
                                             tieBreakerDescription={data.tieBreakerDescription}
                                             tournamentTeams={data.tournamentTeams}
                                             bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                             entryName={data.entry.name} totalTeams={totalTeams}
                                             collegeLogos={data.collegeLogos}
                        />;
                    case 32:
                        return <ThirtyTwoTeams data={data} entryID={entryID} poolID={poolID}
                                               tournamentStarted={data.tournamentStarted}
                                               tieBreakerDescription={data.tieBreakerDescription}
                                               tournamentTeams={data.tournamentTeams}
                                               bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                               entryName={data.entry.name} totalTeams={totalTeams}
                                               collegeLogos={data.collegeLogos}
                        />;
                    case 64:
                        return <SixtyFourTeams data={data} entryID={entryID} poolID={poolID}
                                               tournamentStarted={data.tournamentStarted}
                                               tieBreakerDescription={data.tieBreakerDescription}
                                               tournamentTeams={data.tournamentTeams}
                                               bracketStructure={data.bracketStructure} tieBreaker={data.tieBreaker}
                                               entryName={data.entry.name} totalTeams={totalTeams}
                                               collegeLogos={data.collegeLogos}
                        />;
                    default:
                        return <div>{`No view for bracket length: ${Object.keys(bracket).length}`}</div>
                }
            })()}
        </Box>
    )
};

export default PoolEntryIndex;
