import {useParams, Form} from "react-router-dom";
import {Box, Button, Grid, Switch, TextField} from "@mui/material";
import {useState} from "react";
import MainCard from "../components/MainCard";
import FormControlLabel from "@mui/material/FormControlLabel";

const StartPool = () => {
    const {tournamentID} = useParams();
    const [poolName, setPoolName] = useState('');
    const [entryName, setEntryName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPrivate, setIsPrivate] = useState(true);
    const [maxEntries, setMaxEntries] = useState(1);

    return <Grid container spacing={2.5} justifyContent="center">
        <Grid item xs={12} md={6} lg={7}>
            <MainCard title="Create New Pool">
                <Box sx={{maxWidth: 400, margin: 'auto'}}>
                    <Form method="post" action={`/pools/start/${tournamentID}`} onSubmit={() => setIsLoading(true)}>
                        <TextField
                            disabled={isLoading}
                            fullWidth
                            name="name"
                            label="Pool Name"
                            value={poolName}
                            onChange={(e) => setPoolName(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            disabled={isLoading}
                            fullWidth
                            name="maxEntries"
                            label="Max number of entries per user"
                            type="number"
                            value={maxEntries}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                setMaxEntries(value >= 0 ? value : 1); // Ensure non-negative values
                            }}
                            margin="normal"
                            required
                            InputProps={{
                                inputProps: {min: 0},
                            }}
                        />
                        <TextField
                            disabled={isLoading}
                            fullWidth
                            name="entryName"
                            label="Entry Name"
                            value={entryName}
                            onChange={(e) => setEntryName(e.target.value)}
                            margin="normal"
                            required
                        />
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isPrivate}
                                        onChange={(e) => setIsPrivate(e.target.checked)}
                                        color="primary"
                                        disabled={isLoading}
                                    />
                                }
                                label={`${isPrivate ? 'Private': 'Public'} pool, ${isPrivate ? 'only invited emails will be able to join the pool.' : 'anyone with the link can join.'}`}
                            />
                            <input
                                type="hidden"
                                name="privatePool"
                                value={isPrivate.toString()}
                            />
                        </Box>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{mt: 2}}
                        >
                            Create Pool
                        </Button>
                    </Form>
                </Box>
            </MainCard>
        </Grid>
    </Grid>
}

export default StartPool;
