import React from 'react';
import {Box, Button, Card, CardContent, Typography} from "@mui/material";

const InvitedPools = ({pool, setModalPoolID, handleOpen}) => {
    const dates = pool.tournaments.tournament_dates;
    const hasStarted = new Date(dates.find(item => item.date_type === 'start')?.date) <= new Date()
    return (
        <Card sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <CardContent>
                <Typography variant="h5" component="div" sx={{fontWeight: "bold", marginBottom: 1}}>
                    {pool.name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{marginBottom: 1}}>
                    Created At: {new Date(pool.created_at).toLocaleString()}
                </Typography>
                <Typography variant="subtitle2" sx={{marginBottom: 2}}>
                    Entries: {pool.tournament_pool_entries.length}
                </Typography>
            </CardContent>
            <Box sx={{padding: 2}}>
                {!hasStarted && <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                        setModalPoolID(pool.id);
                        handleOpen();
                    }}
                    color="primary"
                >
                    Create Entry
                </Button>}
            </Box>
        </Card>
    );
};

export default InvitedPools;
