import React, {useState} from 'react';
import {useLoaderData} from "react-router-dom";
import {Box, Grid, Typography, } from "@mui/material";
import CreateEntryModal from "../components/modals/createEntry";
import hdate from "human-date";
import InvitedPools from "../components/InvitedPools";
import FullScreenLoader from "../components/FullScreenLoader";
import WelcomeBanner from "../sections/dashboard/default/WelcomeBanner";
import RoundIconCard from "../components/cards/statistics/RoundIconCard";

const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const [modalPoolID, setModalPoolID] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const data = useLoaderData()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <WelcomeBanner/>
            </Grid>
            <FullScreenLoader isLoading={isLoading}/>
            <CreateEntryModal isOpen={open} handleClose={handleClose} poolID={modalPoolID}/>
            <Grid container pt={5}>
                {
                    data?.invitedPools?.length > 0 && <Grid item>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Invited Pools
                        </Typography>
                    </Grid>
                }
                <Grid container spacing={3}>
                    {data?.invitedPools?.map((pool) => (
                        <Grid item xs={12} sm={6} md={4} key={pool.id}>
                            <InvitedPools pool={pool} setModalPoolID={setModalPoolID} handleOpen={handleOpen}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {data?.pools?.map((pool, index) => (
                <>
                    <Grid item xs={12} lg={4} sm={6}>
                        <RoundIconCard
                            key={index}
                            primary={pool.name}
                            privatePool={pool.tournament_pools.private_pool}
                            secondary={pool.tournament_pools.name}
                            content={`Created ${hdate.relativeTime(pool.created_at)}`}
                            iconPrimary={pool.tournaments.type}
                            tournament={pool.tournaments.name}
                            poolID={pool.tournament_pools.id}
                            entryID={pool.id}
                            setIsLoading={setIsLoading}
                            tournamentDates={pool.tournaments.tournament_dates}
                        />
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

export default Dashboard;
