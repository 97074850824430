import {Link, Navigate, useLoaderData} from "react-router-dom";
import {useAuth} from "../AuthProvider";
import {AvatarGroup, Box, Button, Card, CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import React, {useState} from "react";

import FullScreenLoader from "../components/FullScreenLoader";
import Avatar from "../components/@extended/Avatar";
import {NFLLogos} from "../components/NFLLogos";
import ProfileCard from "../sections/apps/profiles/user/ProfileCard";
import UmbrellaTable from "./standingsTable";

export function getWinnerAvatar(winner, collegeLogos) {
    const collegeLogoSource = collegeLogos.find((item) => {
        return item.school.toLowerCase() === winner.toLowerCase();
    })?.logo

    if (collegeLogoSource === undefined) {
        return <Avatar alt={winner.toLowerCase()}>
            <NFLLogos name={winner}/>
        </Avatar>
    }
    return (
        <Avatar alt={winner.toLowerCase()} src={collegeLogoSource}/>
    );
}

const Standings = () => {
    const theme = useTheme();
    const {user} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const data = useLoaderData();
    if (!user) {
        return <Navigate to='/login' replace/>;
    }

    return <Grid container>
        <Grid item xs={12}>
            <ProfileCard title={data.tournamentName} link={`/pools/${data.poolID}`} linkText={"Back to Pool"}/>
        </Grid>
        <FullScreenLoader isLoading={isLoading}/>
        {/*<Box sx={{maxWidth: 'lg', mx: 'auto', p: 3}}>*/}
        {/*    <Stack spacing={2}>*/}
        {/*        {data.standings.map((entry, index) => (*/}
        {/*            <Card key={entry.entryID} sx={{*/}
        {/*                '&:hover': {*/}
        {/*                    boxShadow: 6,*/}
        {/*                    transition: 'box-shadow 0.3s ease-in-out'*/}
        {/*                },*/}
        {/*                [theme.breakpoints.up('md')]: {*/}
        {/*                    minWidth: '500px'*/}
        {/*                }*/}

        {/*            }}>*/}
        {/*                <CardContent>*/}
        {/*                    /!* Header Section *!/*/}
        {/*                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 3}}>*/}
        {/*                        <Box>*/}
        {/*                            <Button*/}
        {/*                                onClick={() => setIsLoading(true)}*/}
        {/*                                variant="outlined"*/}
        {/*                                color="primary"*/}
        {/*                                component={Link}*/}
        {/*                                startIcon={<>{index === 0 ?*/}
        {/*                                    <TbRosetteNumber1/> : index === data.standings.length - 1 &&*/}
        {/*                                    <ImCrying/>}</>}*/}
        {/*                                to={`/pools/${entry.poolID}/entry/${entry.entryID}`}>*/}
        {/*                                {entry.name}*/}
        {/*                            </Button>*/}
        {/*                            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>*/}
        {/*                                <Typography variant="h4" color="primary.main">*/}
        {/*                                    {entry.points} {entry.points === 1 ? 'point' : 'points'}*/}
        {/*                                </Typography>*/}
        {/*                            </Box>*/}
        {/*                            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>*/}
        {/*                                <Typography variant="caption" color="text.secondary">*/}
        {/*                                    {entry.possiblePoints} possible points*/}
        {/*                                </Typography>*/}
        {/*                            </Box>*/}
        {/*                        </Box>*/}
        {/*                        <Box>*/}
        {/*                            <Typography color="text.secondary" variant="subtitle2">*/}
        {/*                                Tie Breaker*/}
        {/*                            </Typography>*/}
        {/*                            <Chip*/}
        {/*                                icon={<MdOutlineSportsMartialArts/>}*/}
        {/*                                variant="combined"*/}
        {/*                                color="warning"*/}
        {/*                                label={entry.tieBreaker}*/}
        {/*                                size="small"*/}
        {/*                            />*/}
        {/*                        </Box>*/}
        {/*                    </Box>*/}

        {/*                    /!* Predictions Grid *!/*/}
        {/*                    <Grid container spacing={3}>*/}

        {/*                        /!* Winner *!/*/}
        {/*                        <Grid item xs={12} md={4}>*/}
        {/*                            <Stack spacing={2}>*/}
        {/*                                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>*/}
        {/*                                    <Typography variant="h6">Winner</Typography>*/}
        {/*                                </Box>*/}
        {/*                                <Stack spacing={1}>*/}
        {/*                                    <AvatarGroup max={2}>*/}
        {/*                                        {getWinnerAvatar(entry.winner, data.collegeLogos)}*/}
        {/*                                    </AvatarGroup>*/}
        {/*                                </Stack>*/}
        {/*                            </Stack>*/}
        {/*                        </Grid>*/}


        {/*                        /!* Semi Finals *!/*/}
        {/*                        <Grid item xs={12} md={4}>*/}
        {/*                            <Stack spacing={2}>*/}
        {/*                                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>*/}
        {/*                                    <Typography variant="h6">Semi Finals</Typography>*/}
        {/*                                </Box>*/}
        {/*                                <Stack spacing={1}>*/}
        {/*                                    <AvatarGroup max={5}>*/}
        {/*                                        {entry?.semiFinals.map((team) => (*/}
        {/*                                            getWinnerAvatar(team, data.collegeLogos)*/}
        {/*                                        ))}*/}
        {/*                                    </AvatarGroup>*/}
        {/*                                </Stack>*/}
        {/*                            </Stack>*/}
        {/*                        </Grid>*/}


        {/*                        /!* Quarter Finals *!/*/}
        {/*                        <Grid item xs={12} md={4}>*/}
        {/*                            <Stack spacing={2}>*/}
        {/*                                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>*/}
        {/*                                    <Typography variant="h6">Quarter Finals</Typography>*/}
        {/*                                </Box>*/}
        {/*                                <Stack spacing={1}>*/}
        {/*                                    <AvatarGroup max={4}>*/}
        {/*                                        {entry?.quarterFinals.map((team) => (*/}
        {/*                                            getWinnerAvatar(team, data.collegeLogos)*/}
        {/*                                        ))}*/}
        {/*                                    </AvatarGroup>*/}
        {/*                                </Stack>*/}
        {/*                            </Stack>*/}
        {/*                        </Grid>*/}
        {/*                    </Grid>*/}
        {/*                </CardContent>*/}
        {/*            </Card>*/}
        {/*        ))}*/}
        {/*    </Stack>*/}
        {/*</Box>*/}
        <Grid item xs={12} mt={4}>
            <UmbrellaTable standingsData={data}/>
        </Grid>
    </Grid>;
};

export default Standings;
